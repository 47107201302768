import React from 'react';
import {
    IconButton, Grid,Typography
} from "@material-ui/core";
import { KeyboardBackspace } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import {SearchComponent} from '../search'
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
   root:{
       paddingTop:theme.spacing(2),
       paddingLeft:4,
       paddingRight:theme.spacing(2),
   },
   flexCenter:{
       display:'flex',
       alignItems:'center'
   }
}));

export const SubHeader = (props) => {

    const classes = useStyles(props);

    const onBackBtnClicked = () =>{
        props?.backButtonAction() && props.backButtonAction()
    }

    return (
          <div className={classes.root}>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item className={classes.flexCenter}>
                        <IconButton onClick={onBackBtnClicked}>
                            <KeyboardBackspace htmlColor="rgba(17, 15, 71, 1)" />
                        </IconButton>
                        <Typography variant="body1" color="textPrimary" style={{fontWeight:'bold'}}>
                        {props?.title}
                        </Typography>
                    </Grid>
                    {
                        !props?.hideSearch &&
                        <Grid item>
                            <SearchComponent
                                hideSearch={props?.hideSearch}
                                buttonName={props?.buttonName}
                                buttonAction={props?.buttonAction}
                            />
                        </Grid>
                    }
                </Grid>
          </div>
    )
}

SubHeader.propTypes = {
   
}

SubHeader.defaultProps = {
   title:'Go Back'
}