

/*
createdBy:Manikandan
createdAt:25/01/2022
email:mani@crayond.co
*/
export const styles = (theme) => ({
    formControl: {
      margin: theme.spacing(1),
    },
    root: {
      background: "#f4f4f5",
      // padding:theme.spacing(2)
      // height: "90vh",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      height: 40,
      alignSelf: "center",
      margin: "0px 15px",
      backgroundColor: "#eee",
    },
    paper: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #0000001A",
      borderRadius: "10px",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0 0 11px rgba(33,33,33,.2)",
        transition: "0.5s",
      },
    },
    containerStyle: {
      padding: theme.spacing(2),
      paddingTop: 0,
      overflowX: "hidden",
    },
    backButton: {
      marginRight: theme.spacing(1),
      float: "right",
    },
  
    addbtn: {
      color: "#fff",
      background: "#131947",
      borderRadius: 10,
      cursor: "pointer",
  
      fontSize: 14,
      alignItems: "center",
      padding: "6px 13px 8px 6px",
      display: "flex",
      width: "fit-content",
      float: "right",
      margin: "24px 20px 6px",
  
      [theme.breakpoints.only("xs")]: {
        margin: "24px 20px 6px",
      },
    },
    dialogbox: {
      padding: 24,
    },
    dialogBox: {
      padding: "24px 24px 0 24px",
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      padding: "14px 25px",
      borderBottom: "2px solid #C7CCCC",
    },
    savebtns: {
      color: "#fff",
      background: "#131947",
      borderRadius: 10,
      cursor: "pointer",
      fontSize: 14,
      alignItems: "center",
      padding: "10px 14px",
      width: "83%",
      margin: "0 20px",
      textAlign: "center",
    },
    card: {
      margin: "12px 20px",
      borderRadius: 8,
      boxShadow: "0px 5px 23px #00000014",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      "& .MuiCardHeader-title": {
        fontSize: 15,
        fontWeight: 500,
      },
    },
    choosebtn: {
      background: "#f4f4f5",
      borderRadius: 6,
      padding: "3px 3px",
      margin: "5px 0px",
      textTransform: "capitalize",
      fontSize: 13,
      letterSpacing: 1,
      opacity: 0.8,
      "& a": {
        textDecoration: "unset",
        color: "#222222bf",
      },
    },
    tabTitle: {
      flexGrow: 1,
      textAlign: "center",
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      fontSize: 17,
      letterSpacing: 1,
      [theme.breakpoints.only("xs")]: {
        textAlign: "left",
        fontSize: 16,
        display: "none",
      },
    },
    appbar: {
      background: theme.palette.background.paper,
      boxShadow: "none",
      width: "100%",
      color: "rgba(0, 0, 0, 0.87)",
      borderBottom: "1px solid rgb(0 0 0 / 11%)",
    },
    menulist: {
      color: "rgb(177 176 176)",
      margin: "0px 12px",
      fontWeight: 400,
      padding: 20,
      cursor: "pointer",
      fontSize: 15,
      letterSpacing: 1,
    },
    active: {
      borderBottom: "2px solid #131947",
    },
    backBtn: {
      background: "gray",
      "&:hover": {
        background: "gray",
      },
      color: "white",
      textTransform: "capitalize",
      borderRadius: "8px",
    },
    btnContain: {
      display: "flex",
      justifyContent: "flex-end",
    },
    backDiv: {
      margin: "23px 30px 10px 0px",
    },
    buttonView: {
      boxShadow: "0px 5px 23px #00000014",
      fontSize: 8,
      textTransform: "capitalize",
      letterSpacing: 1,
      "& .MuiTypography-body1": {
        fontWeight: 500,
      },
    },
    hoverEffect: {
      "&:hover": {
        transform: "scale(1.1)",
        transition: "transform 0.8s",
      },
    },
    sync: {
      color: "white",
      cursor: "pointer",
      fontSize: 14,
    },
    formControl: {
      marginTop: "12px",
      minWidth: 120,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    connectGitrepo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    connectGitLabel: {
      fontSize: "20px",
    },
    connectGitBtn: {
      color: `${theme.palette.primary.main}`,
      padding: "10px 20px",
      fontSize: 15,
    },
    instructions: {
      paddingBottom: 10,
    },
    projectTextFiled: {
      background: "#D6D6D614 0% 0% no-repeat padding-box",
      border: "1px solid #110F4714",
      borderRadius: "4px",
      opacity: 1,
    },
  
    debug: {
      "& *": {
        // outline: "1px solid"
      },
    },
  });