export const themes = {
    default:'default',
    dark:'dark'
} 

export const alertProps = {
    vertical:{
        top:'top',
        bottom:'bottom',
    },
    horizontal:{
        left:'left',
        right:'right',
        center:'center'
    },
    severity:{
        success:'success',
        error:'error',
        warning:'warning',
        info:'info',
    }
}

export const DrawerProps = {
    direction: {
        top: "top",
        bottom: "bottom",
        left: "left",
        right: "right",
    },
    variant: {
        permanent: "permanent",
        persistent: "persistent",
        temporary: "temporary",
    },
};

export const localStorageKeys = {
    auth_token:'auth_token'
}

export const netWorkCallMethods = {
    get:'GET',
    post:'POST',
    put:'PUT',
    delete:'DELETE',
    update:'UPDATE'
}

export const treeData = [{
    id: 1,
    name: "Apollo group of hospitals",
    checkedValue: "unchecked",
    children: [
      {
        id: 2,
        name: "Apollo hospitals - India",
        checkedValue: "unchecked",
        children: [
          {
            id: 3,
            name: "Apollo hospitals - India",
            checkedValue: "unchecked",
          },
          {
            id: 4,
            name: "Apollo hospitals - India",
            checkedValue: "unchecked",
          },
        ],
      },
      {
        id: 5,
        name: "Apollo hospitals - China",
        checkedValue: "unchecked",
      },
      {
        id: 6,
        name: "Apollo hospitals - Canada",
        checkedValue: "unchecked",
      },
      {
        id: 7,
        name: "Apollo hospitals - USA",
        checkedValue: "unchecked",
      },
      {
        id: 8,
        name: "Apollo hospitals - Japan",
        checkedValue: "unchecked",
      },
      {
        id: 9,
        name: "Apollo hospitals - Brazil",
        checkedValue: "unchecked",
      },
      {
        id: 10,
        name: "Apollo hospitals - France",
        checkedValue: "unchecked",
      },
    ],
  }];