
/*
createdBy:Manikandan
createdAt:25/01/2022
email:mani@crayond.co
*/
import Dialog from "../dialog/index";
import { Avatar, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { styles } from '../homeRouterPageStyle';
import { makeStyles } from "@material-ui/core/styles";
import { colorCliPalette } from "../../utils/constants";
import { SubHeader } from "../subNav";
import ProjectInfo from '../projectSetup/projectInfo';
import Theme from '../projectSetup/theme';
import Access from '../projectSetup/access';
import InitiationProperties from '../projectSetup/initiationProperties';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CustomStepper from "../stepper";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteCompoent from "../DeleteDialogBox";


const useStyle = makeStyles(styles);
var darkColorCli = colorCliPalette;

function ProjectCompoent(props){
    const  classes = useStyle();
    const { backToClient,addProject,handleAddProjectClose,
        formSteps,activeStep,handleState,handleUploadIcon,state,
        handleChangeTab,tabValue,themeSwitcher,handleSwitcher,handleObject,handlefontFamily,alert,
        updateProject,submitProject,forwardStep,backWardStep,data,fetchProjectEditData,
        handleDeleteProjectDrawerOpen,clientColorChanges,
        handleDeleteProjectDrawerClose,deleteProject,switchToNextStep,fontFamily,is_update,
        getTools,addproject,enableEdit,handleDeleteProject } = props;
    return(

        <React.Fragment>
             
             <SubHeader
              title="Projects"
              backButtonAction={() => backToClient()}
              // hideSearch
              buttonName="Add Project"
              buttonAction={() => addProject()}
            />

            {/* dialog box */}
            <Dialog
              // noGutters
              open={addproject}
              handleClose={() => handleAddProjectClose()}
              component={
                <div style={{ width: "100%" }} className={classes.debug}>
                  <Typography className={classes.title}>
                    {enableEdit === true
                      ? "Edit Project"
                      : "Add Project"}
                    <span style={{ float: "right", marginTop: -8 }}>
                      {" "}
                      <IconButton onClick={() => addProject()}>
                        <HighlightOffIcon />
                      </IconButton>
                    </span>
                  </Typography>

                  {/* <FormStepper/> */}
                  {/* Main StepperForm Container */}
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {/* Stepper */}
                        <CustomStepper
                          steps={formSteps}
                          activeStep={activeStep}
                          switchToNextStep={switchToNextStep}
                        />
                      </Grid>

                      {(() => {
                        switch (activeStep) {
                          case 0 /*Case 0 */:
                            return (
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                style={{ margin: "0px 48px" }}
                              >
                                <ProjectInfo
                                  handleState={handleState}
                                  handleUploadIcon={handleUploadIcon}
                                  {...state}
                                />
                              </Grid>
                            );

                          case 1 /*Case 1 */:
                            return (
                              <Grid
                                container
                                item
                                xs={12}
                                style={{ overflow: "hidden" }}
                              >
                                {" "}
                                <Theme
                                  handleChange={handleChangeTab}
                                  value={tabValue}
                                  themeSwitcher={themeSwitcher}
                                  handleSwitcher={handleSwitcher}
                                  handlefontFamily={handlefontFamily}
                                  fontFamily={fontFamily}
                                  handleObject={handleObject}
                                  handleState={handleState}
                                  {...state}
                                />
                              </Grid>
                            );

                          case 2 /*Case 2 */:
                            return (
                              <Access
                                handleState={handleState}
                                {...state}
                              />
                            );

                          case 3:
                            return (
                              <Grid container item xs={12}>
                                <InitiationProperties
                                  handleState={handleState}
                                  alert={alert}
                                  {...state}
                                />
                              </Grid>
                            );

                          default:
                            return;
                        }
                      })()}

                      {/* <ProjectInfo /> */}
                      <Grid item xs={12}>
                        {/* Back and Next Button */}
                        <div style={{ marginTop: 20 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              activeStep === 4
                                ? is_update
                                  ? updateProject()
                                  : submitProject()
                                : forwardStep()
                            }
                            className={classes.backButton}
                          >
                            {activeStep === 4 ? "Finish" : "Next"}
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={activeStep === 0}
                            onClick={() => backWardStep()}
                            className={classes.backButton}
                          >
                            Back
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              }
            />
            {
              <Grid container className={classes.containerStyle}>
                {data?.map((v, index) => {
                  return (
                    <>
                      {index === -1 ? (
                        ""
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          style={{ padding: 16 }}
                          className={classes.hoverEffect}
                        >
                          <div
                            className={`${classes.paper} ${classes.flexCenter}`}
                            style={{
                              minHeight: 150,
                              justifyContent: "center",
                              flexDirection: "column",
                              marginBottom: 0,
                              position: "relative",
                            }}
                            onClick={() => getTools(v)}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "7px",
                                right: "10px",
                              }}
                            >
                              <EditIcon
                                style={{ padding: 5 }}
                                onClick={(event) => {
                                  fetchProjectEditData(v?._id);
                                  event.stopPropagation();
                                }}
                              />

                              <DeleteIcon
                                style={{ padding: 5 }}
                                onClick={(event) => {
                                  handleDeleteProjectDrawerOpen(v?._id);
                                  event.stopPropagation();
                                }}
                              />
                            </div>
                          

                            <Avatar
                              aria-label="recipe"
                              className={classes.avatar}
                              style={{
                                // marginRight:15,
                                marginBottom: 5,
                                textTransform: "capitalize",
                                backgroundColor: `${
                                  darkColorCli[clientColorChanges(index)]
                                }`,
                              }}
                            >
                              {v?.projectname.slice(0, 1)}
                            </Avatar>

                            {/* <Button> */}
                            <Typography
                              variant="h6"
                              style={{ textAlign: "center", fontWeight: 400 }}
                            >
                              {v?.projectname}
                            </Typography>
                            <DeleteCompoent deleteDrawer={deleteProject} description={'Are you sure, you want to delete this project?'} handleDeleteDrawerClose={handleDeleteProjectDrawerClose} handleDelete={handleDeleteProject}/>

                            {/* <Dialog
                              open={deleteProject}
                              transprant={true}
                              handleClose={(event) => {
                                handleDeleteProjectDrawerClose();
                                event.stopPropagation();
                              }}
                              component={
                                <div>
                                  <div
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      height: "18vh",
                                    }}
                                  >
                                    <Typography
                                      className={classes.title}
                                      style={{ fontSize: "1.2rem" }}
                                    >
                                      Are you sure you want to delete this
                                      project?
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginTop: "15px",
                                      padding: "10px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      style={{ marginRight: "10px" }}
                                      onClick={(event) => {
                                        handleDeleteProjectDrawerClose();
                                        event.stopPropagation();
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={(event) => {
                                        handleDeleteProject();
                                        event.stopPropagation();
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </div>
                              }
                            /> */}
                            {/* </Button> */}
                          </div>
                        </Grid>
                      )}
                    </>
                  );
                })}
              </Grid>
            }
        </React.Fragment>
    )
}

export default ProjectCompoent;