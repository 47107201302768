import {
  Accordion,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FontSelector from "../fontSelector";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  themeAccord: {
    "&.MuiAccordionSummary-content": {
      justifyContent: "space-between",
    },
  },

  // Accordion
  accordionRoot: {
    boxShadow: "unset",
    borderBottom: "1px solid #110F4714",
  },

  // Accordion Summary
  accordionSummaryRoot: {
    padding: "0px",
  },
  accordionSummaryContent: {
    "&.Mui-expanded": {
      margin: "12px 0px",
    },
  },

  // Accordion Detail
  accordionDetailRoot: {
    padding: "0px !important",
  },
  fontsAvailable: {
    marginTop: 5,
    border: "1px solid #ccc",
    borderRadius: "5px",

    "& .MuiToggleButtonGroup-root": {
      display: "block",
      "& .MuiToggleButton-root": {},
    },
  },
  fontFamily: {
    border: "1px solid #ccc",
  },
}));


const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
      
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      
    },
  },
}))(InputBase);


export default function TypographyTab({ fonts, metaData,masterFontWeight,handleObject }) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} >
        {/* Palette Generator*/}

        <div className={classes.root}>
          {metaData.map((data) => (
            <Accordion classes={{ root: classes.accordionRoot }}>
              <AccordionSummary
                className={classes.themeAccord}
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: classes.accordionSummaryRoot,
                  content: classes.accordionSummaryContent,
                }}
              >
                {/* <Typography className={classes.heading}>
                  {data.code}
                </Typography> */}
                <Typography color="textSecondary">
                  {data.description}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "24px",
                }}
                classes={{
                  root: classes.accordionDetailRoot,
                }}
              >
                <div style={{margin: "8px 0px"}}>
                  <Typography>Font Family </Typography>
                  <div className={classes.fontsAvailable}>
                    <FontSelector
                      isSingle={true}
                      fonts={fonts}
                      className={classes.fontFamily}
                      fontfamilyvalue={data.typofonts}
                      handleObject = {  handleObject}
                        statename =  "masterTypograpy"
                        objectKey= {data?.code}
                        objectName = "typofonts"

                    />
                  </div>
                </div>
                 
               
                  <Box mt={1} display="flex" justifyContent="space-between">
                    <Grid container >
                      <Grid item xs={12} md={6}>
                      <Typography>Font Size </Typography>
                      <BootstrapInput type="text" value={data.fontsize} onChange={(e)=>handleObject('masterTypograpy',data?.code,'fontsize',e.target.value)} />

                      </Grid>
                      <Grid item xs={12} md={6}>
                      <Typography>Font Weight</Typography>
                      
                      <Select
                       id='font'
                       input={<BootstrapInput/>}
                       style={{width: "100%"}}
                       value={data.fontweight}
                       onChange={(e)=>handleObject('masterTypograpy',data?.code,'fontweight',e.target.value)}
                      >
                        <MenuItem value='' disabled >Select Option</MenuItem>
                        {masterFontWeight?.length>0 && masterFontWeight?.map((option) => (
                          <MenuItem value={option}>{option.label}</MenuItem>
                        ))}
                      </Select>
                      </Grid>
                    </Grid>
                  </Box>
                
                <Box my={0.5}>
                  <div style={{margin: "4px 4px 0px 0px"}}>
                    <Typography>Line Height</Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems:"center"
                      }}
                    >
                      <Slider
                        aria-labelledby="input-slider"
                        value={data.linehgt}
                       onChange={(e,value)=>handleObject('masterTypograpy',data?.code,'linehgt',value)}
                        style={{marginRight: 10}}
                      />
                      <span
                        style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                      >{`${data.linehgt} px`}</span>
                    </div>
                  </div>
                  <div style={{margin: "4px 4px 0px 0px"}}>
                    <Typography>Letter Spacing </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems:"center"
                      }}
                    >
                      <Slider
                        aria-labelledby="input-slider"
                        value={data.ltrspace}
                        onChange={(e,value)=>handleObject('masterTypograpy',data?.code,'ltrspace',value)}
                        style={{marginRight: 10}}
                      />
                      <span
                        style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                      >{`${data.ltrspace} px`}</span>
                    </div>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Grid>
    </>
  );
}
