import React, { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles, withStyles ,useTheme} from "@material-ui/core";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import StepConnector from '@material-ui/core/StepConnector';



const QontoConnector =  withStyles(theme=>({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      borderColor:theme.palette.primary.main ,
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles( theme => ({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
}));



export default function CustomStepper(props) {
  const { steps = [], activeStep,switchToNextStep } = props;

  return (
    <Stepper
      color="secondary"
      activeStep={activeStep}
      alternativeLabel
      connector={<QontoConnector />}
    >
      {steps.map((label,_index) => (
        <Step key={label} onClick={()=>switchToNextStep(_index)}>
          <StepLabel >{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
