import React from 'react';
import {
    IconButton, TextField, Grid, Button, LinearProgress, Tooltip, FormControl, Hidden, Select, MenuItem, Badge
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    textInput: {
        height: 40,
        backgroundColor: '#FFFFFF',
        width: 300,
        marginRight:10,
        [theme.breakpoints.down("xs")]: {
            width: '100%',
        },
        borderRadius: 10,
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#110F471A'
        }
    },
    root:{
        display:'flex',
        alignItems:'center'
    },
    btn: {
        boxShadow: '0px 5px 23px #00000014',
        fontSize: 13,
        textTransform: 'capitalize',
        letterSpacing: 1,
        height: 40,
        minWidth: 150,
        [theme.breakpoints.down('xs')]: {
            // display: 'none'
            minWidth:105
        }
    },
}));

export const SearchComponent = (props) => {

    const classes = useStyles(props);

    const [state, setState] = React.useState({
        search: ""
    });

    const changeState = (key, value) => {
    }

    const onActionButton = () =>{
        props?.buttonAction() && props.buttonAction()
    }

    return (
          <div className={classes.root}>
              {/* {
                  !props?.hideSearch &&
                  <TextField
                    id="search_bar"
                    placeholder={props?.placeholder}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        className: classes.textInput,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon className={classes.searchBar} />
                            </InputAdornment>
                        ),
                    }}
                    value={state.search}
                    onChange={(e) => changeState('search', e.target.value)}
                />
              } */}
                   <Button
                        variant='contained'
                        color='primary'
                        className={classes.btn}
                        onClick={onActionButton}
                    >
                        {props?.buttonName}
                    </Button>
          </div>
    )
}

SearchComponent.propTypes = {
   placeholder:PropTypes.string,
   hideSearch:PropTypes.bool
}