import { Grid } from "@material-ui/core";
import React from "react";
import CustomAutocomplete from "../autoComplete";

const users = [
  { userName: "Sridhar", designation: "developer" },
  { userName: "Gokul", designation: "developer" },
  { userName: "Manikandan", designation: "developer" },
  { userName: "Hari Krishnan", designation: "developer" },
  { userName: "Kathir", designation: "developer" },
];

export default function Access(props) {
  return (
    <Grid item xs={12}>
      {/* AutoCompelete */}
      <div style={{padding:"0px 42px"}}>
        <CustomAutocomplete
          placeholder={"Search users"}
          label={""}
          options={props.masterUsers}
          // options={props.masterLanguage}
          handleState = {props.handleState}
          value = {props.users}
          fieldkey='users'
          access={true}
        />
      </div>
    </Grid>
  );
}
