import * as React from "react"

function PaletteIcon(props) {
  return (
    
    <div style={{display: 'flex' ,alignItems: 'center', justifyContent: 'center'}}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.632}
      height={12}
      {...props}
    >

      <path
        d="M6.158 0A5.837 5.837 0 000 5.526a2.082 2.082 0 002.053 2.053 7.063 7.063 0 001.353-.167c.407-.08.776-.149.857-.149.434 0 .543.126.714.475A8.99 8.99 0 015.41 9.27a4.338 4.338 0 00.79 1.82 2.744 2.744 0 002.19.91c1.876 0 4.238-2 4.238-5.526A6.481 6.481 0 006.158 0zm0 .947a5.519 5.519 0 015.526 5.526c0 3.1-2.141 4.579-3.291 4.579a1.684 1.684 0 01-1.462-.57 3.626 3.626 0 01-.6-1.437 8.328 8.328 0 00-.5-1.724 1.742 1.742 0 00-1.565-1.005 5.6 5.6 0 00-1.041.167 6.467 6.467 0 01-1.169.149A1.241 1.241 0 01.951 5.527C.947 3.276 2.914.947 6.158.947zm0 .947a.789.789 0 10.789.789.789.789 0 00-.789-.788zm2.211.632a.789.789 0 10.789.789.789.789 0 00-.79-.789zm1.263 1.895a.789.789 0 10.789.789.789.789 0 00-.789-.789zm.316 2.211a.789.789 0 10.789.789.789.789 0 00-.79-.789zM8.369 8.211A.789.789 0 109.158 9a.789.789 0 00-.79-.789z"
        fill={props.color}
      />
    </svg>
    <span  style={{textTransform:"capitalize",marginLeft:4 ,color:props.color}}>Palette</span>
    </div>
    
  
    
  )
}

export default PaletteIcon;