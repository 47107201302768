
/*
createdBy:Manikandan
createdAt:25/01/2022
email:mani@crayond.co
*/

import { Avatar, Button, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { styles } from '../homeRouterPageStyle';
import { makeStyles } from "@material-ui/core/styles";
import { colorCliPalette } from "../../utils/constants";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyle = makeStyles(styles);
var darkColorCli = colorCliPalette;

function ClientCompoent(props){
    const  classes = useStyle();
    const { clientSearch,clientList,deleteClientOpen,navProject,clientColorChanges } = props;
    return(

        <React.Fragment>
             <Grid container style={{ padding: 16 }}>
                    <Grid container justify="flex-end">
                      <TextField
                        type="text"
                        label="Search"
                        size="small"
                        variant="outlined"
                        style={{ width: 300, marginBottom: 8 }}
                        placeholder="Search . . ."
                        onChange={(e) => clientSearch(e.target.value)}
                      />
                    </Grid>
                    {clientList?.map((v, index) => {
                      return (
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justify="space-between"
                          className={classes.paper}
                          onClick={(event) =>{
                            navProject(v?._id, v?.clientname, v?.clientid);
                            event.stopPropagation();
                          }
                          }
                        >
                          <div className={classes.flexCenter}>
                            <Avatar
                              aria-label="recipe"
                              className={classes.avatar}
                              style={{
                                marginRight: 15,
                                textTransform: "capitalize",
                                backgroundColor: `${
                                  darkColorCli[clientColorChanges(index)]
                                }`,
                              }}
                            >
                              {v?.clientname.slice(0, 1)}
                            </Avatar>
                            <Typography style={{ textAlign: "center" }}>
                              {v?.clientname}
                            </Typography>
                          </div>
                          <div className={classes.flexCenter}>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonView}
                            >
                              <Typography variant="body1">
                                {`${v?.project[0]?.count}`}&nbsp;&nbsp; Projects
                              </Typography>
                              <ChevronRightIcon />
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonView}
                              style={{ marginLeft: 10 }}
                              onClick={(event) => {
                                deleteClientOpen(v?._id);
                                event.stopPropagation();
                              }}
                            >
                              <Typography variant="body1">Delete</Typography>
                            </Button>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
        </React.Fragment>
    )
}

export default ClientCompoent