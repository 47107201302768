import * as React from "react"

function FontsIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={16} {...props}>
      <g fill={props.color}>
        <text
          transform="translate(23 12)"
          fontSize={props.fontSize}
          fontFamily="NunitoSans-SemiBold,Nunito Sans"
          fontWeight={props.fontWeight}
          color={props.color}
        >
          <tspan x={0} y={0}>
            {"Fonts"}
          </tspan>
        </text>
        <path d="M12.86 2a.569.569 0 00-.529.383l-3.971 10.8a.611.611 0 00.324.777.558.558 0 00.734-.342l.889-2.417h5.108l.889 2.417a.558.558 0 00.734.342.611.611 0 00.324-.777l-3.972-10.8A.569.569 0 0012.86 2zm0 2.255L14.972 10h-4.224zM3.774 4.4a.569.569 0 00-.518.375l-3.215 8.4a.612.612 0 00.313.781.557.557 0 00.739-.331L1.715 12h4.134l.622 1.625a.557.557 0 00.739.331.612.612 0 00.313-.781l-3.215-8.4a.568.568 0 00-.534-.375zm.008 2.2l1.608 4.2H2.175z" />
      </g>
    </svg>
  )
}

export default FontsIcon;
