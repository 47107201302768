import React,{useState} from 'react';
import {LanguageContext} from './contexts';

function AppLanguages(props){
   const [languages, setLanguages] = useState({
       list:[]
   })
   return(
       <LanguageContext.Provider value={{...languages,setLanguages}}>
                {props.children}
       </LanguageContext.Provider>
   )
}

export default AppLanguages;