import React, { useState ,useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button, Grid } from "@material-ui/core";




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#fff",
      borderRadius: "15px 15px 0 0",
      padding: "20px",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  containerAlign: {
    padding: "10px 20px",
  },
  btnSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  btn: {
    textTransform: "none",
    borderRadius: "8px",
  },
  tabsEdit: {
    backgroundColor: "#f4f4f5",
    color: "#000",
  },
  indiactorDisplay: {
    display: "none",
  },
  successBtn:{
    textTransform: "none",
    borderRadius: "8px",
    backgroundColor:"#45149b",
    color:"white"
  },
  dangerBtn:{
    textTransform: "none",
    borderRadius: "8px",
  }
}));




export default function Privileges(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);


  const toolsSet = props.mytools;
  const toolsName = toolsSet
  const roleid = props.role;
  const {userRole,setPermission} = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
   let filterRoles,roleIndex,flattedRoleArray,filterArrayObject;
  // console.log(userRole);
  
    
     filterRoles =  userRole.map(role=>{
          if(roleid ===role.master_roles.id){
              
             return role.master_roles
          }
         return null
      })
      roleIndex = filterRoles.findIndex(index => index !== null);
      flattedRoleArray = filterRoles.flat();
     filterArrayObject = Object.assign({},...flattedRoleArray);
     console.log(filterArrayObject);
    //  console.log(roleIndex);
    
    
    
      


   const checkPermissonStatus = (route,label) => {
  
        let checkPermissonResult = Object.keys(filterArrayObject).some(routeName => routeName === route);

        if(!checkPermissonResult){
          return false;
        }
       let checkLabelInRoute = Object.entries(filterArrayObject).filter(eachRoute=> {
         if(eachRoute[0]=== route){
           return eachRoute[1].includes(label)
         } 
         return undefined;
        });
      
       if( checkLabelInRoute.length > 0){
        return true;
       }else{
        return false;
       }

   }
   
  


  const handlepermission =(master,params,status)=>{

    

    let permissonsCopy = JSON.parse(JSON.stringify(filterArrayObject));
    
    /*  Privileges format
       
         {
            [masterPage]:[],
            
         }
      
      */

      const result = Object.keys(permissonsCopy).some(eachRoute => eachRoute === master)

       if(!result){
        const schema = {
            [master]:[]
          }
          permissonsCopy = {

            ...permissonsCopy,
            ...schema
          
          }
        }
      //  console.log(permissonsCopy);
       Object.entries(permissonsCopy).map(eachRoute => {
             
              if(eachRoute[0] === master){
                 let updatedRoute = {}
                  if(status){
                    updatedRoute = {
                      [master]:[...eachRoute[1],params]
                   }
                  }else{
                      
                     let matchParams = eachRoute[1].indexOf(params);
                     eachRoute[1].splice(matchParams,1);
                     updatedRoute= {
                       [master]:[...eachRoute[1]],
                     }
                     
                  }
              
                  permissonsCopy = {
                    ...permissonsCopy,
                    ...updatedRoute
                 }

              }
           })            
         let final = userRole.splice(roleIndex,1,{master_roles:{...permissonsCopy}});
        console.log("Send to Roles.component ",final);

        
        setPermission(final);
        
        

       
       }
       

      

       



        

    
   
   

    

 
  

      
  



   
  return (
    
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            indicator: classes.indiactorDisplay,
            root: classes.tabsEdit,
          }}
        >
          <Tab label="Configuration" {...a11yProps(0)} />
          <Tab label="WorkFlow" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          {toolsName.map((v) => (
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              key={v}
              className={classes.containerAlign}
            >
              <Typography style={{ paddingTop: "3px" }}>{v}</Typography>
              <div className={classes.flexCenter}></div>
              <div className={classes.btnSpacing}>
                <Button variant="contained" classes={{ root: checkPermissonStatus(v,"create") ? classes.successBtn : classes.dangerBtn}}
                onClick={() => {
                  let status = checkPermissonStatus(v,"create")
                  console.log(status);
                  handlepermission(v,"create",!status)
                  
                }}
               
                 
                >
                  Create
                </Button>
                <Button variant="contained" classes={{ root: checkPermissonStatus(v,"read") ? classes.successBtn : classes.dangerBtn}}
                  onClick={() => {
                    let status = checkPermissonStatus(v,"read")
                    
                    handlepermission(v,"read",!status)
                    
                  }}
                 
                
                
                >
                  Read
                </Button>
                <Button variant="contained" classes={{ root: checkPermissonStatus(v,"update") ? classes.successBtn : classes.dangerBtn}}
                 
                 onClick={() => {
                  let status = checkPermissonStatus(v,"update")
                  
                  handlepermission(v,"update",!status)
                  
                }}
               
                 
                >
                  Update
                </Button>
                <Button variant="contained" classes={{ root: checkPermissonStatus(v,"delete") ? classes.successBtn : classes.dangerBtn}}
                  onClick={() => {
                    let status = checkPermissonStatus(v,"delete")
                    handlepermission(v,"delete",!status)
                    
                  }}
                 
                 
                >
                  Delete
                </Button>
              </div>
            </Grid>
          ))}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Properties not assigned yet
      </TabPanel>
    </div>
  );
}
