import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 221,
    fontSize: 13,
  },
  text: {
    flexGrow: 1,
  },
  // New Classes
  selections: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& div": {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
  },
  autocomplete: {
    background: "#D6D6D614 0% 0% no-repeat padding-box",
    borderRadius: "4px",
    opacity: 1,
    "& .MuiInputBase-root": {
      paddingRight: "10px !important",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
}));

export default function CustomAutocomplete(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState([]);

  const handleRemove = (removedOption) => {
    const filteredOptions = props.value.filter((val) => val.value !== removedOption);
    props.handleState(props.fieldkey,filteredOptions);

  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="combo-box-demo"
        className={classes.autocomplete}
        options={props.options}
        getOptionLabel={(option) => option.label}
        value={props.value}
        style={{ width: "100%" }}
        disableCloseOnSelect
        onChange={(event, newValue) => {
          props.handleState(props.fieldkey,newValue);
          console.log(newValue);
        }}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disableClearable
            forcePopupIcon={false}
            placeholder={props.placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <div className={classes.text}>
              {option.label}
              {/* <Typography color="textSecondary">{option.value}</Typography> */}
            </div>

            <div>{selected ? "selected" : "select"}</div>
          </React.Fragment>
        )}
      />

      {props.access ? (
        <>
        <div style={{ marginTop: "42px" }}>
        <Typography style={{ paddingBottom:10}} color="textSecondary">{`${props?.value?.length>0?'Members':''}`}</Typography>
          {props?.value?.length>0 && props?.value?.map((selectedOption, idx) => (
            <div style={{ width: "100%" }}>
              <div >
               

                <div key={selectedOption.label} className={classes.selections}>
                  <div>
                    <AccountCircleIcon   style={{fontSize:32,color:"#c1c1c1"}}/>
                    <Typography>{selectedOption.label}</Typography>
                  </div>

                  <div>
                    {/* <Typography color="textSecondary">
                      {selectedOption.value}
                    </Typography> */}

                    <IconButton
                      style={{ padding: 4 }}
                      size="small"
                      onClick={() => handleRemove(selectedOption.value)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
              </div>
              <Divider
                light
                style={{ width: "100%", margin: "4px 0px 6px 0px" }}
              />
            </div>
          ))}
        </div>
        
        </>
      ) : (
        <>
          {props.value.map((selectedOption, idx) => (
            <div style={{ width: "100%" }}>
              <div key={selectedOption.label} className={classes.selections}>
                <Typography>{selectedOption.label}</Typography>

                <div>
                  {/* <Typography color="textSecondary">
                    {selectedOption.value}
                  </Typography> */}

                  <IconButton
                    style={{ padding: 4 }}
                    size="small"
                    onClick={() => handleRemove(selectedOption.value)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
              <Divider
                light
                style={{ width: "100%", margin: "4px 0px 6px 0px" }}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}
