import React from "react";
import { withRouter } from "react-router-dom";
import Users from "../Users";
import Roles from "../Roles";
import Config from "../../config";

import {
  withStyles,
  Grid,
  TextField,
  Typography,
  Card,
  Switch,
  // CardHeader,
  Avatar,
  Button,
  IconButton,
  Divider,
  Menu,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Input,
  Chip,
  // AppBar,
  // Toolbar,
} from "@material-ui/core";
// import Select from '@material-ui/core/Select';
import AddIcon from "@material-ui/icons/Add";
import Masters from "../masterr";
import Dialog from "../dialog/index";
import axios from "axios";
import Alert from "../alert";
// import DeleteIcon from "@material-ui/icons/Delete";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { v4 as uuidv4, v4 } from "uuid";
// import { GraphQLNonNull } from "graphql";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import { keys } from "@material-ui/core/styles/createBreakpoints";
import RepositoryMain from "../repository";
import PermissionList from "../permission";
import { withAllContexts } from "../../hocs";
import TabComponent from "../tabs";
import { DrawerProps } from "../../utils";
import config from "../../config";
import AddClient from "./addClient";
import {
  formSteps,
  tabNameValue,
  projectPaletteInfo,
} from "../../utils/constants";
import { stateProperties } from "./stateProperties";
import { styles } from "../homeRouterPageStyle";
import {
  readDocument,
  projectReadDocument,
  clientReadDocument,
  uploadImage,
  bulkUpsertDocument,
  projectIdReadDocument,
  deleteProjectEdgeDocument,
} from "../../function/commonapi";
import ClientCompoent from "../Client";
import ToolCompoent from "../Tools";
import ProjectCompoent from "../Projects";
import DeleteCompoent from "../DeleteDialogBox";
import MasterDataFetch from "./masterDataFetch";
import { handleSubmitProject, handleUpdateProject, handleFetchProjectEditData } from './projectCRUDFucntion';
var toolURLwithID = [
  {
    id: config.StratigicModelerToolId,
    url: config.StratigicModelerURL,
  },
  {
    id: config.EnterpriseModelerToolId,
    is_dbdesign: false,
    url: config.EnterpriseModelerURL,
  },
  {
    id: config.QPASBuilderToolId,
    url: config.QPASBuilderURL,
  },
  {
    id: config.EntityDesignerToolId,
    is_dbdesign: true,
    url: config.EntityDesignerURL,
  },
  {
    id: config.UICustomFormDesignerToolId,
    url: config.UICustomFormDesignerURL,
  },
  {
    id: config.UIFormTemplateBuilderToolId,
    url: config.UIFormTemplateBuilderURL,
  },
  {
    id: config.UIAutoRendererToolId,
    url: config.UIAutoRendererURL,
  },
  {
    id: config.UIReportBuilderToolId,
    url: config.UIReportBuilderURL,
  },
  {
    id: config.UIDashboardBuilderToolId,
    url: config.UIDashboardBuilderURL,
  },
  {
    id: config.UIFlowBuilderToolId,
    url: config.UIFlowBuilderURL,
  },
  {
    id: config.RuleBuilderToolId,
    url: config.RuleBuilderURL,
  },
  {
    id: config.ServiceFlowBuilderToolId,
    url: config.ServiceFlowBuilderURL,
  },
  {
    id: config.JobSchedulerToolId,
    url: config.JobSchedulerURL,
  },
  {
    id: config.JobMonitorToolId,
    url: config.JobMonitorURL,
  },
  {
    id: config.DocumentWorkflowBuilderToolId,
    url: config.DocumentWorkflowBuilderURL,
  },
  {
    id: config.ChatbotWorkflowBuilderToolId,
    url: config.ChatbotWorkflowBuilderURL,
  },
  {
    id: config.IntegratedTestEnginToolId,
    url: config.IntegratedTestEnginURL,
  },
  {
    id: config.AlertHubToolId,
    url: config.AlertHubURL,
  },
  {
    id: config.AVCVideoConferencingToolId,
    url: config.AVCVideoConferencingURL,
  },
  {
    id: config.RuntimeAdminConsoleToolId,
    url: config.RuntimeAdminConsoleURL,
  },
  {
    id: config.ELKDashboardToolId,
    url: config.ELKDashboardURL,
  },
  {
    id: config.PromethusDashboardToolId,
    url: config.PromethusDashboardURL,
  },
  {
    id: config.NiFiToolId,
    url: config.NiFiURL,
  },
  {
    id: config.MessageCatalogToolId,
    url: config.MessageCatalogURL,
  },
  {
    id: config.FormConfiguratorsToolId,
    url: config.FormConfiguratorsURL,
  },
  {
    id: config.IDMToolId,
    url: config.IDMURL,
  },
  {
    id: config.UrlshortnerId,
    url: config.Urlshortner,
  },
  {
    id: config.ServiceRegistryId,
    url: config.ServiceRegistryURL,
  },
  {
    id: config.PrinterQueueId,
    url: config.PrinterQueueURL,
  },
];

class EntityComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...stateProperties };
  }

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });

  componentDidMount() {
    debugger;
    this.getClient();
    // localStorage.setItem("header_name", "QDM");
    this.props.handleHeaderName(this.state.headerName[0]);

    this.getMasterInfo();
  }
  getMasterInfo = async () => {
    try {
      let masterFetchData = await MasterDataFetch(this.state);
      debugger;
      this.setState((prev) => ({
        ...prev,
        ...masterFetchData.data,
      }));
    } catch (error) {
      this.handleSnackBar("error", "Project Master data fetching error ! ");
    }
  };

  handlefontFamily = (value) => {
    this.setState({ fontFamily: value });
  };

  handleChangeFont = (event, value) => {
    this.setState({ selectedFont: value });
  };

  handleChangeTab = (event, value) => {
    this.setState({ tabValue: value });
  };
  handleSwitcher = (event) => {
    this.setState({ themeSwitcher: event.target.checked });
  };

  async getProject(id) {
    this.handleBackDrop(true, "loading");
    let payload = {
      _id: id,
      entity: "projects",
    };
    await projectReadDocument(payload)
      .then((response) => {
        debugger;

        if (response?.data?.Code === 201) {
          if (response?.data?.result?.length > 0) {
            this.setState({ data: response?.data?.result, client_id: id });
          } else {
            this.setState({ data: [], client_id: id });
          }
        } else {
          this.handleSnackBar("error", "something went wrong ! ");
        }
      })
      .catch((err) => {
        this.handleSnackBar("error", "something went wrong ! ");
      });
    this.handleBackDrop(false, "");
  }

  forwardStep = () => {
    const state = this.state;
    let validation = this.handleValidation();
    if (validation) {
      return;
    }
    this.setState({
      ...state,
      activeStep: this.state.activeStep + 1,
    });

    console.log(state.activeStep);
  };

  backWardStep = () => {
    const state = this.state;

    this.setState({
      ...state,
      activeStep: this.state.activeStep - 1,
    });
  };

  addProject = () => {
    const state = this.state;
    this.setState({
      addproject: !state.addproject,
      enableEdit: false,
      addprojectvalue: "",
      is_update: false,
      theme: "",
      language: [],
      selectUser: "",
      projectName: "",
      language: [],
      themeType: "light",
      palette: projectPaletteInfo,
      availableFonts: [],
      masterTypograpy: state.cloneMasterTypograpy,
      users: [],
      keyValues: [
        {
          key: "",
          value: "",
        },
      ],
      projectPlatform: [],
      databaseName: "",
      uploadImages: [],
      activeStep: 0,
    });
  };
  onTabClick = (tab) => {
    this.setState({
      ...this.state,
      tabIndex: tab,
      clientList: this.state.listClient,
    });
  };

  switchFunction = () => {
    const { tabIndex } = this.state;
    if (tabIndex === 0) {
      this.addClient();
      let state = this.state;
      this.setState({
        ...state,
        clientDrawer: true,
      });
    } else if (tabIndex === 1) {
      let state = this.state;
      this.setState({
        ...state,
        userDrawer: true,
      });
      this.addUser();
    } else if (tabIndex === 2) {
      this.addRoles();
    }
  };

  giveMeButtonName = () => {
    const { tabIndex } = this.state;
    if (tabIndex === 0) {
      return "Add Client";
    }
  };
  handleClientDrawer = () => {
    let state = this.state;

    this.setState(
      {
        ...state,
        clientDrawer: false,
      },
      () => {
        this.addClient();
      }
    );
  };

  addClient = () => {
    const { drawer, classes } = this.props;
    drawer.setDrawer({
      ...drawer,
      open: this.state.clientDrawer,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddClient
          onSubmitClient={this.onSubmitClient}
          clientList={this.state.clientList}
        />
      ),
    });
  };

  handleDeleteDrawerClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      deleteDrawer: false,
    }));
  };
  handleDeleteClientDrawerClose = () => {
    this.setState({ deleteClient: false });
  };

  handleAddProjectClose = () => {
    this.setState({
      addProject: false,
      anchorEl: null,
    });
  };

  actionDeleteDrawer = (userId) => {
    this.setState((prevState) => ({
      ...prevState,
      deleteDrawer: true,
      deleteUserId: userId,
    }));
  };

  clientSearch = (keyword) => {
    debugger;
    let state = this.state;
    if (keyword === "") {
      let dataList = state.listClient;
      this.setState({ clientList: dataList });
    } else {
      let list = state.listClient;
      let dataLis = [];
      dataLis = list.filter((name) =>
        name.clientname.toLowerCase().includes(keyword.toLowerCase())
      );
      this.setState({ clientList: dataLis });
    }
  };

  deleteClientOpen = (id) => {
    this.setState({ deleteClient: true, client_id: id });
  };

  deleteClient = async () => {
    this.handleBackDrop(true, "loading");
    let params = [
      {
        db_name: config.database,
        entity: "clients",
        is_metadata: false,
        filter: { _id: this.state.client_id },
        doc: {
          activestatus: false,
        },
      },
    ];
    let client_api_value = await bulkUpsertDocument(params);
    if (client_api_value?.data?.Code !== 201) {
      this.handleSnackBar("error", "Client deleted faild ! ");
      this.handleBackDrop(false, "");
      return;
    }
    this.setState((prev) => ({
      ...prev,
      deleteClient: !prev.deleteClient,
    }));
    this.getClient();
    this.handleBackDrop(false, "");
    this.handleSnackBar("success", "Client delete successfully ! ");
  };

  onSubmitClient = async (clientName) => {
    this.handleBackDrop(true, "loading");
    let params = [
      {
        db_name: config.database,
        entity: "clients",
        is_metadata: false,
        doc: {
          clientid: uuidv4(),
          clientname: clientName,
          active: true,
          createdby: "",
          activestatus: true,
        },
      },
    ];
    let client_api_value = await bulkUpsertDocument(params);
    if (client_api_value?.data?.Code !== 201) {
      this.handleSnackBar("error", "Client created faild ! ");
      this.handleBackDrop(false, "");
      return;
    }
    this.getClient();
    this.handleSnackBar("success", "Client created successfully ! ");
    const { drawer } = this.props;
    drawer.setDrawer({
      ...drawer,
      open: false,
    });
    this.handleBackDrop(false, "");
  };

  getClient = async () => {
    await clientReadDocument()
      .then(async (res) => {
        debugger;
        if (res?.data?.Code === 201) {
          let state = this.state;
          state.clientList = res.data.result;
          state.listClient = res.data.result;
          this.setState((prev) => ({
            ...prev,
            ...state,
          }));
        } else {
          this.handleSnackBar("error", "somthing went wrong !");
        }
      })
      .catch((err) => {
        this.handleSnackBar("error", "somthing went wrong !");
      });
  };
  navProject = (id, name, clientUUID) => {
    let insertProjectName = this.state.headerName;
    insertProjectName.push(name);
    debugger;
    this.setState({
      status: "project",
      headerName: insertProjectName,
      client_key: clientUUID,
    });
    this.props.handleHeaderName(insertProjectName[insertProjectName.length - 1]);

    // localStorage.setItem('header_name',name)

    this.getProject(id);
  };
  backToClient = () => {
    let insertProjectName = this.state.headerName;
    insertProjectName.pop();

    localStorage.clear();
    this.setState({
      status: "client",
      headerName: insertProjectName,
    });

    this.props.handleHeaderName(insertProjectName[insertProjectName.length - 1]);
    setTimeout(() => {
      this.getClient();

    }, 0)
    // localStorage.setItem("header_name", "QDM");
  };
  handleCangeClient = (n, v) => {
    const state = this.state;
    if ("email" === n || n === "username") {
      if (v.length <= 0) {
        state.userError[n] = true;
      } else {
        state.userError[n] = false;
      }
    }
  };
  handleCange = (n, v) => {
    debugger;
    //   add project handle change
    const state = this.state;
    if ("email" === n || n === "username") {
      if (v.length <= 0) {
        state.userError[n] = true;
      } else {
        state.userError[n] = false;
      }
    }
    this.setState(
      {
        ...state,
        [n]: v,
      },
      () => {
        this.addUser();
      }
    );
  };

  getTools = async (metadata) => {
    if (typeof metadata === "undefined" || metadata === null) {
      return;
    }
    debugger;
    let insertProjectName = this.state.headerName;
    insertProjectName.push(metadata.projectname);

    let state = this.state;
    this.setState({
      ...state,
      status: "tools",
      headerName: insertProjectName
    });
    this.props.handleHeaderName(insertProjectName[insertProjectName.length - 1]);

    // this.props.handleHeaderName(metadata.metadataname);
    let toolsPayload = {
      entity: "tools",
      filter: "tools.activestatus==true",
    };
    this.setState({
      // tools:response.data.result,
      project_id: metadata._id,
    });
  };
  routerNavigation = async (id, toolid) => {
    let project_vs_tool = {
      entity: "projectvstools",
      filter: `projectvstools.projectid=='${this.state.project_id}' and projectvstools.toolid=='${id}'`,
    };
    await readDocument(project_vs_tool)
      .then((response) => {
        if (response?.data?.Code === 201) {
          let findURL = toolURLwithID?.filter((_) => _.id === toolid)[0] ?? "";
          let newPageUrl = `${findURL.url}?metadata_id=${response.data.result[0].metadataid}`;
          if (typeof findURL.is_dbdesign === "boolean") {
            newPageUrl = newPageUrl + `&isDB=${findURL.is_dbdesign}`;
          }
          let loginDetails = JSON.parse(sessionStorage.payload);

          newPageUrl = newPageUrl + `&token=${loginDetails.access_token}`;
          window.open(newPageUrl, "_blank");
        } else {
          this.handleSnackBar("error", "somthing went wrong !");
        }
      })
      .catch((error) => {
        this.handleSnackBar("error", "somthing went wrong !");
      });
  };

  handleDeleteDrawerClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      addproject: false,
    }));
  };

  backToProject = () => {
    let popUpheaderName = this.state.headerName;
    popUpheaderName.pop();
    this.setState({
      status: "project",
      headerName: popUpheaderName,
    });
    this.props.handleHeaderName(popUpheaderName[popUpheaderName.length - 1]);
  };

  clientColorChanges = (index) => {
    return index % 10;
  };

  //project functions............................ by manikandan.....................

  handleState = (name, value) => {
    if (
      name === "databaseName" &&
      value.length > 1 &&
      !/([A-Za-z]{1}[\w\-]+)$/y.test(value)
    ) {
      return;
    }
    this.setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  handleUploadIcon = async (value) => {
    this.handleBackDrop(true, "uploading");
    let UploadedFiles = [];

    UploadedFiles = await uploadImage(value);
    this.setState((prev) => ({
      ...prev,
      uploadImages: UploadedFiles,
    }));
    this.handleBackDrop(false, "");
  };

  handleObject = (statename, objectkey, objectname, value) => {
    debugger;
    let state = this.state;
    if (statename === "palette") {
      state.palette = state.palette.map((_) => {
        if (objectkey === _.label) {
          _.props = _.props.map((_in) => {
            if (objectname === _in.label) {
              _in.color = value;
            }
            return _in;
          });
        }
        return _;
      });
    } else if (statename === "masterTypograpy") {
      state.masterTypograpy = state.masterTypograpy.map((_) => {
        if (objectkey === _.code) {
          _[objectname] = value;
        }
        return _;
      });
    }

    this.setState((prev) => ({
      ...prev,
      ...state,
    }));
  };

  handleValidation = () => {
    let validatation = this.state.validatation;
    let state = this.state;
    let is_error = false;
    if (state.activeStep !== 4) {
      validatation = validatation.filter(
        (_) => _.stepValue === state.activeStep
      );
    }
    validatation.map((_) => {
      debugger;
      if (!is_error) {
        if (
          _.type === "string" &&
          (!state[_.key] || state[_.key]?.length <= 0)
        ) {
          is_error = true;
          this.handleSnackBar("error", _.msg);
        } else if (
          _.type === "array" &&
          (!state[_.key] || state[_.key]?.length <= 0)
        ) {
          is_error = true;
          this.handleSnackBar("error", _.msg);
        } else if (_.key === "keyValues") {
          debugger;
          for (let i = 0; i < state[_.key].length; i++) {
            let keyvalues = Object.values(state[_.key][i]);
            let lengthofEmpty = keyvalues.filter((_) => !_);
            is_error = lengthofEmpty.length === 1 ? true : false;
            if (is_error) {
              this.handleSnackBar("error", _.msg);
              return;
            }
          }
        } else if (
          _.key === "databaseName" &&
          !/([A-Za-z]{1}[\w\-]+)$/y.test(state[_.key])
        ) {
          is_error = true;
          this.handleSnackBar("error", "database name invalid format");
        }
      }
    });
    return is_error;
  };
  handleDeleteProjectDrawerClose = () => {
    this.setState((prev) => ({
      ...prev,
      deleteProject: !prev.deleteProject,
    }));
  };
  handleDeleteProjectDrawerOpen = (id) => {
    this.setState((prev) => ({
      ...prev,
      deleteProject: !prev.deleteProject,
      _idOfproject: id,
    }));
  };
  handleDeleteProject = async () => {
    this.handleBackDrop(true, "loading");
    let params = [
      {
        db_name: config.database,
        entity: "projects",
        filter: { _id: this.state._idOfproject },
        is_metadata: false,
        doc: {
          activestatus: false,
        },
      },
    ];
    let delete_project = await bulkUpsertDocument(params);
    if (delete_project?.data?.Code !== 201) {
      this.handleSnackBar("error", "delete project faild ! ");
      this.handleBackDrop(false, "");
      return;
    }
    let edgeParams = {
      db_name: config.database,
      entity: "client_project_E",
      filter: `client_project_E._to=='${this.state._idOfproject}'`,
      is_metadata: false,
      doc: {
        activestatus: false,
      },
    };
    let deleteEdge = await deleteProjectEdgeDocument(edgeParams);
    if (deleteEdge?.data?.Code !== 201) {
      this.handleSnackBar("error", "delete project faild in edge collection! ");
      return;
    }
    this.getProject(this.state.client_id);
    this.setState((prev) => ({
      ...prev,
      deleteProject: !prev.deleteProject,
      anchorEl: null,
    }));
    this.handleBackDrop(false, "");
    this.handleSnackBar("success", "delete project successfully ! ");
  };
  submitProject = async () => {
    this.handleBackDrop(true, "loading");
    let validation = this.handleValidation();
    if (validation) {
      this.handleBackDrop(false, "");
      return;
    }
    try {
      const projectSaveRsp = await handleSubmitProject(this.state);
      this.getProject(projectSaveRsp.data.clientId);
      this.setState((prev) => ({
        ...prev,
        addproject: projectSaveRsp.data.addproject,
      }));
      this.handleBackDrop(false, "");
      this.handleSnackBar("success", projectSaveRsp.msg);
    } catch (error) {
      this.handleBackDrop(false, "");
      this.handleSnackBar("error", error.msg);
    }
  };
  //fetch  edit value function .....by manikandan

  fetchProjectEditData = async (id) => {
    this.handleBackDrop(true, "loading");
    try {
      const projectFetchRsp = await handleFetchProjectEditData(this.state, id);
      this.setState((prev) => ({
        ...prev,
        ...projectFetchRsp.data,
        enableEdit: true

      }));
      this.handleBackDrop(false, "");
      // this.handleSnackBar('success', projectFetchRsp.msg);
    } catch (error) {
      this.handleBackDrop(false, "");
      this.handleSnackBar("error", error.msg);
    }
  };
  //update project ......by manikandan...........
  updateProject = async () => {
    this.handleBackDrop(true, "loading");
    let validation = this.handleValidation();
    if (validation) {
      this.handleBackDrop(false, "");
      return;
    }
    try {
      const projectUpdateRsp = await handleUpdateProject(this.state);
      this.getProject(projectUpdateRsp.data.clientId);
      this.setState((prev) => ({
        ...prev,
        addproject: projectUpdateRsp.data.addproject,
        anchorEl: projectUpdateRsp.data.anchorEl,
      }));
      this.handleBackDrop(false, "");
      this.handleSnackBar("success", projectUpdateRsp.msg);
    } catch (error) {
      this.handleBackDrop(false, "");
      this.handleSnackBar("error", error.msg);
    }
  };
  switchToNextStep = (index) => {
    let validation = this.handleValidation();
    if (validation) {
      return;
    }
    this.setState((prev) => ({
      ...prev,
      activeStep: index,
    }));
  };
  handleBackDrop = (open, msg) => {
    this.props.backdrop.setBackDrop({
      open: open,
      message: msg,
    });
  };
  handleSnackBar = (severity, msg) => {
    this.props.alert.setSnack({
      open: true,
      msg,
      severity,
    });
  };
  render() {
    const { classes } = this.props;
    const {
      data,
      clientList,
      status,

      tools,
      deleteClient,
      headerName,
    } = this.state;
    return (
      <div className={classes.root}>
        {/* <DeleteCompoent deleteDrawer={deleteDrawer} description={'Are you sure, you want to delete?'} handleDeleteDrawerClose={this.handleDeleteDrawerClose} handleDelete={this.deleteUser}/> */}

        {headerName.length == 2 ? (
          <>
            <ProjectCompoent
              handleChangeTab={this.handleChangeTab}
              backToClient={this.backToClient}
              addProject={this.addProject}
              handleAddProjectClose={this.handleAddProjectClose}
              switchToNextStep={this.switchToNextStep}
              handleSwitcher={this.handleSwitcher}
              handleState={this.handleState}
              handleUploadIcon={this.handleUploadIcon}
              handlefontFamily={this.handlefontFamily}
              handleObject={this.handleObject}
              state={this.state}
              updateProject={this.updateProject}
              submitProject={this.submitProject}
              forwardStep={this.forwardStep}
              backWardStep={this.backWardStep}
              fetchProjectEditData={this.fetchProjectEditData}
              handleDeleteProjectDrawerOpen={this.handleDeleteProjectDrawerOpen}
              clientColorChanges={this.clientColorChanges}
              handleDeleteProjectDrawerClose={
                this.handleDeleteProjectDrawerClose
              }
              handleDeleteProject={this.handleDeleteProject}
              formSteps={formSteps}
              getTools={this.getTools}
              alert={this.props.alert}
              data={data}
              {...this.state}
            />
          </>
        ) : (
          ""
        )}
        {headerName.length == 3 ? (
          <>
            <ToolCompoent
              routerNavigation={this.routerNavigation}
              backToProject={this.backToProject}
              tools={tools}
            />
          </>
        ) : (
          ""
        )}

        {headerName.length == 1 && (
          <>
            <div>
              <TabComponent
                padding={false}
                onTabClick={this.onTabClick}
                showSearch={
                  this.giveMeButtonName() === "Add Client" ? true : false
                }
                buttonName={this.giveMeButtonName()}
                buttonAction={() => this.switchFunction()}
                tabtitles={tabNameValue}
                clients={
                  <ClientCompoent
                    clientSearch={this.clientSearch}
                    clientList={clientList}
                    deleteClientOpen={this.deleteClientOpen}
                    navProject={this.navProject}
                    clientColorChanges={this.clientColorChanges}
                  />
                }
                users={
                  <div style={{ padding: "10px" }}>
                    <Users />
                  </div>
                }
                roles={
                  <div style={{ padding: "10px" }}>
                    <Roles />
                  </div>
                }
                permission={
                  <div style={{ padding: "10px" }}>
                    <PermissionList />
                  </div>
                }
                repository={
                  <div style={{ padding: "10px" }}>
                    <RepositoryMain />
                  </div>
                }
              />
              <DeleteCompoent
                deleteDrawer={deleteClient}
                description={"Are you sure, you want to delete this client?"}
                handleDeleteDrawerClose={this.handleDeleteClientDrawerClose}
                handleDelete={this.deleteClient}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(withAllContexts(EntityComponent)));
