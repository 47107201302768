
/* eslint-disable */

import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  List,
  ListItem,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
  TextField,
  InputAdornment,
} from "@material-ui/core";
// import AddNewPage from "../../screens/repository/repositoryTabs/addNewPage";
import { DrawerProps } from "../../utils";
import { withAllContexts } from "../../hocs";
import styles from "../../styles/styles";
import {
  readDocument,
  deleteDocument,
  // upsertDocument,
} from "../../function/commonapi";
import Config from "../../config";
import DeleteComponent from "../deleteComponent/deleteComponent";
import EditIcon from "../../assets/icons - Edit.svg";
import DeleteIcon from "../../assets/icons8-Delete.svg";
import { withStyles } from "@material-ui/core/styles";
import searchicon from "../../assets/icons - Search.svg";

// const repositoryInputs = [
//   { name: "Pages" },
//   { name: "Forms" },
//   { name: "Reports" },
//   { name: "Processes" },
// ];

// const IOSSwitch = withStyles((theme) => ({
//   root: {
//     width: 28,
//     height: 16,
//     padding: 0,
//     // margin: theme.spacing(1),
//   },
//   switchBase: {
//     padding: 2,
//     "&$checked": {
//       transform: "translateX(12px)",
//       color: theme.palette.common.white,
//       "& + $track": {
//         backgroundColor: "#0071F2",
//         opacity: 1,
//         border: "none",
//       },
//     },
//     "&$focusVisible $thumb": {
//       color: "#52d869",
//       border: "6px solid #fff",
//     },
//   },
//   thumb: {
//     width: 12,
//     height: 12,
//   },
//   track: {
//     borderRadius: 26 / 2,
//     border: `1px solid ${theme.palette.grey[400]}`,
//     backgroundColor: theme.palette.grey[50],
//     opacity: 1,
//     transition: theme.transitions.create(["background-color", "border"]),
//   },
//   checked: {},
//   focusVisible: {},
// }))(Switch);

function RepositoryMain(props) {
  const classes = styles();
  const [loader, setLoader] = useState(true);
  const [statusCheck, setStatusCheck] = useState({
    statusActive: {},
  });
  const [pageData, setPageData] = useState([]);
  // const [pageData1, setPageData1] = useState([]);

  // const [formRepData, setFromRepData] = useState([]);
  // const [formRepData1, setFromRepData1] = useState([]);

  // const [reportsData, setReportsData] = useState([]);
  // const [reportsData1, setReportsData1] = useState([]);

  // const [processData, setprocessData] = useState([]);
  // const [processData1, setprocessData1] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedIndex, setSelectedIndex] = React.useState("Pages");

  // const handleListItemClick = (e, item) => {
  //   setSelectedIndex(item?.name);
  // };

  // const handleStatusCheck = (e, index, item) => {
  //   let { statusActive } = statusCheck;
  //   if (
  //     statusActive?.[selectedIndex]?.indexOf(item?.Repository?.elementname) > -1
  //   ) {
  //     statusActive?.[selectedIndex].splice(
  //       statusActive?.[selectedIndex]?.indexOf(item?.Repository?.elementname),
  //       1
  //     ) ?? false;
  //   } else {
  //     statusActive[selectedIndex] = statusActive[selectedIndex]
  //       ? statusActive[selectedIndex]
  //       : [];
  //     statusActive[selectedIndex].push(item?.Repository?.elementname);
  //   }
  //   setStatusCheck({
  //     ...statusCheck,
  //     statusActive,
  //   });
  // };

  //API CALL FOR CHANGE PAGE STATUS
  // const handlePageStatus = async (event, item) => {
    //alert(JSON.stringify(item));

  //   let list = {
  //     elementname: item?.Repository?.elementname,
  //     route: item?.Repository?.route,
  //     is_active: !item?.Repository?.is_active,
  //   };
  //   let sendPageData = {
  //     entity: Config.repositoryEntity,
  //     metadataId: Config.metadataid,
  //     isedit: true,
  //     id: item?.Repository?.rep_id,
  //     list,
  //     keyvalue: "rep_id",
  //   };

  //   let addEditpageUpsert = await upsertDocument(sendPageData)
  //     .then((res) => {
  //       if (res?.data?.Code === 201) {
  //         getRepositoryData();

  //         handleAlerts("Page status changed successfully!", true);
  //       } else {
  //         handleAlerts("Something  went wrong", false);
  //       }
  //     })
  //     .catch((error) => {
  //       handleAlerts("Something  went wrong", false);
  //     });
  // };

  //API CALL FOR CHANGE FORM STATUS
  // const handleFormStatus = async (event, item) => {
  //   let list = {
  //     elementname: item?.Repository?.elementname,
  //     is_active: !item?.Repository?.is_active,
  //   };
  //   let sendFormData = {
  //     entity: Config.repositoryEntity,
  //     metadataId: Config.metadataid,
  //     isedit: true,
  //     id: item?.Repository?.rep_id,
  //     list,
  //     keyvalue: "rep_id",
  //   };

  //   let addEditpageUpsert = await upsertDocument(sendFormData)
  //     .then((res) => {
  //       if (res?.data?.Code === 201) {
  //         getRepositoryData();

  //         handleAlerts("Form status changed successfully!", true);
  //       } else {
  //         handleAlerts("Something  went wrong", false);
  //       }
  //     })
  //     .catch((error) => {
  //       handleAlerts("Something  went wrong", false);
  //     });
  // };

  //API CALL FOR CHANGE REPORT STATUS
  // const handleReportStatus = async (event, item) => {
  //   let list = {
  //     elementname: item?.Repository?.elementname,
  //     is_active: !item?.Repository?.is_active,
  //   };
  //   let sendReportsData = {
  //     entity: Config.repositoryEntity,
  //     metadataId: Config.metadataid,
  //     isedit: true,
  //     id: item?.Repository?.rep_id,
  //     list,
  //     keyvalue: "rep_id",
  //   };

  //   let addEditpageUpsert = await upsertDocument(sendReportsData)
  //     .then((res) => {
  //       if (res?.data?.Code === 201) {
  //         getRepositoryData();

  //         handleAlerts("Report status changed successfully!", true);
  //       } else {
  //         handleAlerts("Something  went wrong", false);
  //       }
  //     })
  //     .catch((error) => {
  //       handleAlerts("Something  went wrong", false);
  //     });
  // };

  //API CALL FOR CHANGE PROCESS STATUS
  // const handleProcessStatus = async (event, item) => {
  //   let list = {
  //     elementname: item?.Repository?.elementname,
  //     is_active: !item?.Repository?.is_active,
  //   };
  //   let sendProcessData = {
  //     entity: Config.repositoryEntity,
  //     metadataId: Config.metadataid,
  //     isedit: true,
  //     id: item?.Repository?.rep_id,
  //     list,
  //     keyvalue: "rep_id",
  //   };

  //   let addEditpageUpsert = await upsertDocument(sendProcessData)
  //     .then((res) => {
  //       if (res?.data?.Code === 201) {
  //         getRepositoryData();

  //         handleAlerts("Process status changed successfully!", true);
  //       } else {
  //         handleAlerts("Something  went wrong", false);
  //       }
  //     })
  //     .catch((error) => {
  //       handleAlerts("Something  went wrong", false);
  //     });
  // };

  const handleClickOpen = (item) => {
    setDeleteId(item);
    setOpen(true);
  };

  const handleClose = async (status) => {
    const { alert } = props;
    // let { setSnack } = alert;
    if (status) {
      let sendPageData = {
        entity: Config.master_pages_collection,
        id: [deleteId],
        keyvalue: ["id"],
      };
      await deleteDocument(sendPageData)
        .then((res) => {
          if (res?.data?.data) {
             alert("Document deleted");
          }
        })
        .catch((error) => {
          alert("Document not deleted.");
        });
      setOpen(false);
      getRepositoryData();
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getRepositoryData();
  }, []);

  const getRepositoryData = async () => {
    try {
      let readDocParams = {
        entity: Config.master_pages_collection,
      };

      console.log("what ?",readDocParams);
      let getreadDocument = await readDocument(readDocParams);
    
        // setLoader(false);
        // let filterForms = getreadDocument?.data?.result?.filter(
        //   (i) => i.Repository.rep_type === "Forms"
        // );
        // let filterPages = getreadDocument?.data?.result?.filter(
        //   (i) => i.Repository.rep_type === "Pages"
        // );
        // let filterReports = getreadDocument?.data?.result?.filter(
        //   (i) => i.Repository.rep_type === "Reports"
        // );
        // let filterProcess = getreadDocument?.data?.result?.filter(
        //   (i) => i.Repository.rep_type === "Processes"
        // );
        console.log("data",getreadDocument);
        setPageData(getreadDocument?.data?.result ?? []);
        // setPageData1(filterPages);

        // setFromRepData(filterForms);
        // setFromRepData1(filterForms);

        // setReportsData(filterReports);
        // setReportsData1(filterReports);

        // setprocessData(filterProcess);
        // setprocessData1();
        setLoader(false);
     
    } catch (error) {
      setPageData([]);
    }
  };

  console.log(pageData);
  const handleAlerts = (message, status) => {
    const { alert } = props;
    let { setSnack } = alert;
    setSnack({
      ...alert,
      horizontal: "right",
      msg: message,
      open: true,
      autoHideDuration: 6000,
      severity: status ? "success" : "error",
      vertical: "top",
    });
  };
  const drawerOpening = (e, item) => {
    // const { drawer } = props;
    // drawer.setDrawer({
    //   ...drawer,
    //   open: true,
    //   direction: DrawerProps.direction.right,
    //   variant: DrawerProps.variant.temporary,
    //   component: (
    //     <AddNewPage
    //       getRepositoryData={getRepositoryData}
    //       editName={item?.master_pages?.name}
    //       pageParams={item?.master_pages}
    //       handleAlerts={handleAlerts}
    //       incomingPageData={pageData}
    //       editRoute={item?.master_pages?.route}
    //       editActivePage={item?.master_pages?.active}
    //       editId={item?.master_pages.id}
    //     />
    //   ),
    // });
  };

  // const formdrawerOpening = (e, item) => {
  //   const { drawer } = props;
  //   drawer.setDrawer({
  //     ...drawer,
  //     open: true,
  //     direction: DrawerProps.direction.right,
  //     variant: DrawerProps.variant.temporary,
  //     component: (
  //       <AddNewForms
  //         getRepositoryData={getRepositoryData}
  //         editName={item?.Repository?.elementname}
  //         formParams={item?.Repository}
  //         handleAlerts={handleAlerts}
  //         incomingFormData={formRepData}
  //       />
  //     ),
  //   });
  // };

  // const reportsOpening = (e, item) => {
  //   const { drawer } = props;
  //   drawer.setDrawer({
  //     ...drawer,
  //     open: true,
  //     direction: DrawerProps.direction.right,
  //     variant: DrawerProps.variant.temporary,
  //     component: (
  //       <AddNewReports
  //         getRepositoryData={getRepositoryData}
  //         editName={item?.Repository?.elementname}
  //         reportsParams={item?.Repository}
  //         handleAlerts={handleAlerts}
  //         incomingReportsData={reportsData}
  //       />
  //     ),
  //   });
  // };

  // const processOpening = (e, item) => {
  //   const { drawer } = props;Repositoryrary,
  //     // isLarge:true,
  //     component: (
  //       <AddNewprocesss
  //         getRepositoryData={getRepositoryData}
  //         editName={item?.Repository?.elementname}
  //         processParams={item?.Repository}
  //         handleAlerts={handleAlerts}
  //         incomingProcesssData={processData}
  //       />
  //     ),
  //   });
  // };

  //SEARCH FOR PAGE
  // const searchPageChange = (event) => {
  //   console.log(pageData);
  //   let val = event.target.value.toLowerCase();
  //   const filterDate = pageData.filter((item) => {
  //     return item.Repository?.elementname.toLowerCase().includes(val);
  //   });
  //   setPageData1(filterDate);
  // };
  //SEARCH FOR FORMS
  // const searchFormsChange = (event) => {
  //   console.log(formRepData);
  //   let val = event.target.value.toLowerCase();
  //   const filterDate = formRepData.filter((item) => {
  //     return item.Repository?.elementname.toLowerCase().includes(val);
  //   });
  //   setFromRepData1(filterDate);
  // };
  //SEARCH FOR REPORTS
  // const searchReportsChange = (event) => {
  //   console.log(reportsData);
  //   let val = event.target.value.toLowerCase();
  //   const filterDate = reportsData.filter((item) => {
  //     return item.Repository?.elementname.toLowerCase().includes(val);
  //   });
  //   setReportsData1(filterDate);
  // };
  //SEARCH FOR PROCESS
  // const searchProcessChange = (event) => {
  //   console.log(processData);
  //   let val = event.target.value.toLowerCase();
  //   const filterDate = processData.filter((item) => {
  //     return item.Repository?.elementname.toLowerCase().includes(val);
  //   });
  //   setprocessData1(filterDate);
  // };

  return (
    <div className={classes.contentBox}>
      <Grid container direction="row" spacing={2}>
        {/* <Grid item xs={3}>
          <div className={classes.repoLeftSide}>
            <List>
              {repositoryInputs?.map((item, index) => (
                <ListItem
                  button
                  selected={selectedIndex === item.name}
                  onClick={(e) => handleListItemClick(e, item)}
                  classes={{ selected: classes.active }}
                >
                  <Typography
                    className={classes.innerText}
                    style={{
                      color:
                        selectedIndex === item.name ? "#0071F2" : "#827a77",
                      fontSize: "14px",
                    }}
                  >
                    {item.name}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid> */}

        <Grid item xs={12}>
          {selectedIndex === "Pages" && (
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px 20px",
                border: "1px solid #DCDCDC",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography className={classes.numbersEdit}>Pages</Typography>
                <div style={{ flexGrow: 1 }}></div>
                <TextField
                  id="outlined-search"
                  placeholder="Search field"
                  size="small"
                  style={{ width: "231px", height: "32px" }}
                  type="search"
                  variant="outlined"
                 
                  InputProps={{
                    style: {
                      fontFamily: "poppin",
                      fontSize: "12px",
                      marginRight: "10px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchicon} alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12 },
                  }}
                />
                <Button
                  className={classes.btn}
                  onClick={drawerOpening}
                  contained
                >
                  + Add
                </Button>
              </div>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table
                  className={classes.table}
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ paddingLeft: "35px" }}
                        align="left"
                      >
                        S.No
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left">
                        Description
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left">
                        Route
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ paddingRight: "55px" }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageData?.map((item, index) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          style={{ width: "10%", paddingLeft: "35px" }}
                          align="left"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          align="left"
                        >
                          {item?.master_pages?.name}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          align="left"
                        >
                          {"/"+item?.master_pages?.route}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          align="right"
                          style={{ paddingRight: "35px" }}
                        >
                          <div>
                            {/* <IOSSwitch
                              // checked={
                              //   statusCheck.statusActive?.[selectedIndex]?.indexOf(
                              //     item?.Repository?.elementname
                              //   ) > -1
                              //     ? true
                              //     : false
                              // }
                              checked={
                                item?.Repository?.is_active === true
                                  ? true
                                  : false
                              }
                              onChange={(e) => handlePageStatus(e, item)}
                            /> */}
                            <img
                              src={EditIcon}
                              onClick={(e) => drawerOpening(e, item)}
                              width="12px"
                              style={{ margin: "0px 16px", cursor: "pointer" }}
                            />
                            <img
                              src={DeleteIcon}
                              width="12px"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClickOpen(item?.master_pages?.id)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          {/* -------------------------- Forms --------------------------- */}
          {/* {selectedIndex === "Forms" && (
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px 20px",
                border: "1px solid #DCDCDC",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography className={classes.numbersEdit}>Forms</Typography>
                <div style={{ flexGrow: 1 }}></div>
                <TextField
                  id="outlined-search"
                  placeholder="Search field"
                  size="small"
                  style={{ width: "231px", height: "32px" }}
                  type="search"
                  variant="outlined"
                  onChange={searchFormsChange}
                  InputProps={{
                    style: {
                      fontFamily: "poppin",
                      fontSize: "12px",
                      marginRight: "10px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchicon} alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12 },
                  }}
                />
                <Button
                  className={classes.btn}
                  onClick={formdrawerOpening}
                  contained
                >
                  + Add
                </Button>
              </div>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table
                  className={classes.table}
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ paddingLeft: "35px" }}
                        align="left"
                      >
                        S.No
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left">
                        Description
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ paddingRight: "55px" }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formRepData1?.map((item, index) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          style={{ width: "10%", paddingLeft: "35px" }}
                          align="left"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          align="left"
                        >
                          {item?.Repository?.elementname}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          style={{ paddingRight: "35px" }}
                          align="right"
                        >
                          <div> */}
                            {/* <IOSSwitch
                              // checked={
                              //   statusCheck.statusActive?.[
                              //     selectedIndex
                              //   ]?.indexOf(item?.Repository?.elementname) > -1
                              //     ? true
                              //     : false
                              // }
                              // onChange={(e) =>
                              //   handleStatusCheck(e, index, item)
                              // }
                              checked={
                                item?.Repository?.is_active === true
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleFormStatus(e, item)}
                            /> */}
                            {/* <img
                              src={EditIcon}
                              onClick={(e) => formdrawerOpening(e, item)}
                              width="12px"
                              style={{ margin: "0px 16px", cursor: "pointer" }}
                            />
                            <img
                              src={DeleteIcon}
                              width="12px"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClickOpen(item)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )} */}
          {/* ------------------------- Reports ----------------------------------- */}
          {/* {selectedIndex === "Reports" && (
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px 20px",
                border: "1px solid #DCDCDC",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography className={classes.numbersEdit}>Reports</Typography>
                <div style={{ flexGrow: 1 }}></div>
                <TextField
                  id="outlined-search"
                  placeholder="Search field"
                  size="small"
                  style={{ width: "231px", height: "32px" }}
                  type="search"
                  onChange={searchReportsChange}
                  variant="outlined"
                  InputProps={{
                    style: {
                      fontFamily: "poppin",
                      fontSize: "12px",
                      marginRight: "10px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchicon} alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12 },
                  }}
                />
                <Button
                  className={classes.btn}
                  onClick={reportsOpening}
                  contained
                >
                  + Add
                </Button>
              </div>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table
                  className={classes.table}
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ paddingLeft: "35px" }}
                        align="left"
                      >
                        S.No
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left">
                        Description
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ paddingRight: "55px" }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportsData1?.map((item, index) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          style={{ width: "10%", paddingLeft: "35px" }}
                          align="left"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          align="left"
                        >
                          {item?.Repository?.elementname}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          style={{ paddingRight: "35px" }}
                          align="right"
                        >
                          <div>
                            <IOSSwitch
                              // checked={
                              //   statusCheck.statusActive?.[
                              //     selectedIndex
                              //   ]?.indexOf(item?.Repository?.elementname) > -1
                              //     ? true
                              //     : false
                              // }
                              // onChange={(e) =>
                              //   handleStatusCheck(e, index, item)
                              // }
                              checked={
                                item?.Repository?.is_active === true
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleReportStatus(e, item)}
                            />
                            <img
                              src={EditIcon}
                              onClick={(e) => reportsOpening(e, item)}
                              width="12px"
                              style={{ margin: "0px 16px", cursor: "pointer" }}
                            />
                            <img
                              src={DeleteIcon}
                              width="12px"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClickOpen(item)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )} */}
          {/* -------------------------------- Process ------------------------------------ */}
          {/* {selectedIndex === "Processes" && (
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px 20px",
                border: "1px solid #DCDCDC",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography className={classes.numbersEdit}>Process</Typography>
                <div style={{ flexGrow: 1 }}></div>
                <TextField
                  id="outlined-search"
                  placeholder="Search field"
                  size="small"
                  style={{ width: "231px", height: "32px" }}
                  type="search"
                  variant="outlined"
                  onChange={searchProcessChange}
                  InputProps={{
                    style: {
                      fontFamily: "poppin",
                      fontSize: "12px",
                      marginRight: "10px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchicon} alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12 },
                  }}
                />
                <Button
                  className={classes.btn}
                  onClick={processOpening}
                  contained
                >
                  + Add
                </Button>
              </div>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table
                  className={classes.table}
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ paddingLeft: "35px" }}
                        align="left"
                      >
                        S.No
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left">
                        Description
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ paddingRight: "55px" }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processData1?.map((item, index) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          style={{ width: "10%", paddingLeft: "35px" }}
                          align="left"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          align="left"
                        >
                          {item?.Repository?.elementname}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                          style={{ paddingRight: "35px" }}
                          align="right"
                        >
                          <div>
                          
                            <img
                              src={EditIcon}
                              onClick={(e) => processOpening(e, item)}
                              width="12px"
                              style={{ margin: "0px 16px", cursor: "pointer" }}
                            />
                            <img
                              src={DeleteIcon}
                              width="12px"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClickOpen(item)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )} */}
        </Grid>
        {/* -------------------------------- delete ----------------------------------  */}
        <DeleteComponent open={open} deleteClose={handleClose} />
      </Grid>
    </div>
  );
}

export default withAllContexts(RepositoryMain);
