import * as React from "react"

function WebIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22.168}
      height={21.07}
      {...props}
    >
      <path
        d="M2.494 0A2.507 2.507 0 000 2.494v11.638a2.507 2.507 0 002.494 2.494H7.2V19.4H5.265a.831.831 0 100 1.663H7.9a.831.831 0 00.27 0H14a.831.831 0 00.27 0h2.63a.831.831 0 100-1.663h-1.94v-2.774h4.711a2.507 2.507 0 002.494-2.494V2.494A2.507 2.507 0 0019.674 0zm0 1.663h17.18a.819.819 0 01.831.831v11.638a.819.819 0 01-.831.831h-5.406a.831.831 0 00-.27 0H8.172a.831.831 0 00-.27 0H2.494a.819.819 0 01-.831-.831V2.494a.819.819 0 01.831-.831zM3.6 3.879a.831.831 0 00-.831.831v7.2a.831.831 0 00.831.831h3.879a.831.831 0 00.831-.831v-7.2a.831.831 0 00-.831-.831zm6.65 0a.831.831 0 00-.831.831v7.2a.831.831 0 00.831.831h8.313a.831.831 0 00.831-.831v-7.2a.831.831 0 00-.831-.831zM4.434 5.542H6.65v5.542H4.434zm6.65 0h6.65v5.542h-6.65zM8.867 16.626H13.3V19.4H8.867z"
        fill={props.color}
      />
    </svg>
  )
}

export default WebIcon
