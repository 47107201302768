import dotenv from "dotenv";
dotenv.config();

let config = {};
// config.entity_api_url = `${process.env.REACT_APP_SECURITY}://${process.env.REACT_APP_ENTITY_DOMAIN}:${process.env.REACT_APP_APIPORT}`;
config.entity_api_url = process.env.REACT_APP_ARANGO_DOMAIN_NAME;
config.file_api_url = `${process.env.REACT_APP_FILE_RENDER_API}/${process.env.REACT_APP_DATABASE}`;
config.key_cloak_api = process.env.REACT_APP_KEY_CLOAK_API;
config.key_cloak_db = process.env.REACT_APP_KEY_CLOAK_DB;
config.key_cloak_secret_key = process.env.REACT_APP_KEY_CLOAK_SECRET_KEY;
config.key_cloak_client_id = process.env.REACT_APP_KEY_CLOAK_CLIENT_ID;
config.language_entity = process.env.REACT_APP_LANGUAGE_ENTITY;
config.database = process.env.REACT_APP_DATABASE;
config.userEntity = process.env.REACT_APP_USERS;
config.message_catalog_entity = process.env.REACT_APP_MESSAGE_CATALOG;
config.api_url = process.env.REACT_APP_API_ENDPOINT;
config.graphql = process.env.REACT_APP_GQL_ENDPOINT;
config.socket = process.env.REACT_APP_SOCKET;
config.qpas_entity = process.env.REACT_APP_QPAS_ENTITY;
config.Strategic_Modeler = process.env.REACT_APP_Strategic_Modeler; //167.71.228.116:9015
config.Enterprise_Modeler = process.env.REACT_APP_Enterprise_Modeler; //167.71.228.116:9000
config.QPAS_Builder = process.env.REACT_APP_QPAS_Builder; //167.71.228.116:9010
config.Entity_Designer = process.env.REACT_APP_Entity_Designer; //167.71.228.116:9000
config.UI_Custom_Form_Designer = process.env.REACT_APP_UI_Custom_Form_Designer; //167.71.228.116:9001
config.UI_Form_Template_Builder =
  process.env.REACT_APP_UI_Form_Template_Builder; //ippc.netlify.app
config.UI_Auto_Renderer = process.env.REACT_APP_UI_Auto_Renderer; //167.71.228.116:9001
config.UI_Reports_Builder = process.env.REACT_APP_UI_Reports_Builder; //167.71.228.116:9001
config.UI_Dashboard_Builder = process.env.REACT_APP_UI_Dashboard_Builder; //167.71.228.116:9001
config.UI_Flow_Builder = process.env.REACT_APP_UI_Flow_Builder; //167.71.228.116:9001
config.Rule_Builder = process.env.REACT_APP_Rule_Builder; //13.71.3.63:7001
config.Service_Service_Flow_Builder =
  process.env.REACT_APP_Service_Service_Flow_Builder; //drive.google.com
config.Job_Scheduler = process.env.REACT_APP_Job_Scheduler;
config.Job_Monitor = process.env.REACT_APP_Job_Monitor;
config.Task_Document_Worfklow_Builder =
  process.env.REACT_APP_Task_Document_Worfklow_Builder;
config.Chatbot_Workflow_Builder =
  process.env.REACT_APP_Chatbot_Workflow_Builder; //neurolozix.com
config.Integrated_Test_Engine = process.env.REACT_APP_Integrated_Test_Engine; //managedcare.scanner.testocean.in
config.Alerts_Hub = process.env.REACT_APP_Alerts_Hub; //164.52.208.253
config.AVC_Video_Conferencing = process.env.REACT_APP_AVC_Video_Conferencing; //ainqa-telehealth.live
config.Runtime_Admin_Console = process.env.REACT_APP_Runtime_Admin_Console; //52.172.177.66:8529
config.ELK_Dashboard = process.env.REACT_APP_ELK_Dashboard; //13.71.3.63:8072
config.Promethus_Dashboard = process.env.REACT_APP_Promethus_Dashboard; //13.71.3.63:9091
config.NiFi = process.env.REACT_APP_NiFi; //167.71.228.116:9012
config.Message_Catalog = process.env.REACT_APP_Message_Catalog; //167.71.228.116:9005
config.Form_configurator = process.env.REACT_APP_FORM_CONFIGURATOR;
config.nifi_api = process.env.REACT_APP_NIFI_API;
config.master_roles_collection = process.env.REACT_APP_MASTER_ROLES;
config.master_pages_collection = process.env.REACT_APP_MASTER_PAGES;
//admin console IDM info
config.idmDatabase = process.env.REACT_APP_ADMIN_IDM_DATABASE;
config.idmMetadata = process.env.REACT_APP_ADMIN_IDM_METADATA;
//master tools id

config.StratigicModelerToolId = process.env.REACT_APP_StratigicModelerToolId;
config.EnterpriseModelerToolId = process.env.REACT_APP_EnterpriseModelerToolId;
config.QPASBuilderToolId = process.env.REACT_APP_QPASBuilderToolId;
config.EntityDesignerToolId = process.env.REACT_APP_EntityDesignerToolId;
config.UICustomFormDesignerToolId =
  process.env.REACT_APP_UICustomFormDesignerToolId;
config.UIFormTemplateBuilderToolId =
  process.env.REACT_APP_UIFormTemplateBuilderToolId;
config.UIAutoRendererToolId = process.env.REACT_APP_UIAutoRendererToolId;
config.UIReportBuilderToolId = process.env.REACT_APP_UIReportBuilderToolId;
config.UIDashboardBuilderToolId =
  process.env.REACT_APP_UIDashboardBuilderToolId;
config.UIFlowBuilderToolId = process.env.REACT_APP_UIFlowBuilderToolId;
config.RuleBuilderToolId = process.env.REACT_APP_RuleBuilderToolId;
config.ServiceFlowBuilderToolId =
  process.env.REACT_APP_ServiceFlowBuilderToolId;
config.JobSchedulerToolId = process.env.REACT_APP_JobSchedulerToolId;
config.JobMonitorToolId = process.env.REACT_APP_JobMonitorToolId;
config.DocumentWorkflowBuilderToolId =
  process.env.REACT_APP_DocumentWorkflowBuilderToolId;
config.ChatbotWorkflowBuilderToolId =
  process.env.REACT_APP_ChatbotWorkflowBuilderToolId;
config.IntegratedTestEnginToolId =
  process.env.REACT_APP_IntegratedTestEnginToolId;
config.AlertHubToolId = process.env.REACT_APP_AlertHubToolId;
config.AVCVideoConferencingToolId =
  process.env.REACT_APP_AVCVideoConferencingToolId;
config.RuntimeAdminConsoleToolId =
  process.env.REACT_APP_RuntimeAdminConsoleToolId;
config.ELKDashboardToolId = process.env.REACT_APP_ELKDashboardToolId;
config.PromethusDashboardToolId =
  process.env.REACT_APP_PromethusDashboardToolId;
config.NiFiToolId = process.env.REACT_APP_NiFiToolId;
config.MessageCatalogToolId = process.env.REACT_APP_MessageCatalogToolId;
config.FormConfiguratorsToolId = process.env.REACT_APP_FormConfiguratorsToolId;
config.IDMToolId = process.env.REACT_APP_IDMToolId;
config.UrlshortnerId = process.env.REACT_APP_UrlShortnerId;
config.ServiceRegistryId = process.env.REACT_APP_SERVICEREGISTRYID;
config.PrinterQueueId = process.env.REACT_APP_PrinterQueueId;

config.Urlshortner = process.env.REACT_APP_URLSHORTNERBuilderURL;
//tools URL
config.StratigicModelerURL = process.env.REACT_APP_StratigicModelerURL;
config.EnterpriseModelerURL = process.env.REACT_APP_EnterpriseModelerURL;
config.QPASBuilderURL = process.env.REACT_APP_QPASBuilderURL;
config.EntityDesignerURL = process.env.REACT_APP_EntityDesignerURL;
config.UICustomFormDesignerURL = process.env.REACT_APP_UICustomFormDesignerURL;
config.UIFormTemplateBuilderURL =
  process.env.REACT_APP_UIFormTemplateBuilderURL;
config.UIAutoRendererURL = process.env.REACT_APP_UIAutoRendererURL;
config.UIReportBuilderURL = process.env.REACT_APP_UIReportBuilderURL;
config.UIDashboardBuilderURL = process.env.REACT_APP_UIDashboardBuilderURL;
config.UIFlowBuilderURL = process.env.REACT_APP_UIFlowBuilderURL;
config.RuleBuilderURL = process.env.REACT_APP_RuleBuilderURL;
config.ServiceFlowBuilderURL = process.env.REACT_APP_ServiceFlowBuilderURL;
config.JobSchedulerURL = process.env.REACT_APP_JobSchedulerURL;
config.JobMonitorURL = process.env.REACT_APP_JobMonitorURL;
config.DocumentWorkflowBuilderURL =
  process.env.REACT_APP_DocumentWorkflowBuilderURL;
config.ChatbotWorkflowBuilderURL =
  process.env.REACT_APP_ChatbotWorkflowBuilderURL;
config.IntegratedTestEnginURL = process.env.REACT_APP_IntegratedTestEnginURL;
config.AlertHubURL = process.env.REACT_APP_AlertHubURL;
config.AVCVideoConferencingURL = process.env.REACT_APP_AVCVideoConferencingURL;
config.RuntimeAdminConsoleURL = process.env.REACT_APP_RuntimeAdminConsoleURL;
config.ELKDashboardURL = process.env.REACT_APP_ELKDashboardURL;
config.PromethusDashboardURL = process.env.REACT_APP_PromethusDashboardURL;
config.NiFiURL = process.env.REACT_APP_NiFiURL;
config.MessageCatalogURL = process.env.REACT_APP_MessageCatalogURL;
config.FormConfiguratorsURL = process.env.REACT_APP_FormConfiguratorsURL;
config.IDMURL = process.env.REACT_APP_IDMURL;
config.ServiceRegistryURL = process.env.REACT_APP_SERVICEREGISTRYURL;
config.PrinterQueueURL = process.env.REACT_APP_PrinterQueueURL;

config.Entity_Adoptor_URL = process.env.REACT_APP_ENTITY_ADOPTOR_URL;
export default config;
