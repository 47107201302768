import { makeStyles, withStyles } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  fontBtn: {
    backgroundColor: ' #110F4705!important',
    color: '#9696A9 !important',
   
  },
  iconStyle: {},
  selectedFont: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: " #FFFFFF !important",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px !important",
    
  },
  Platformtext: {
    color: `${theme.palette.primary.main}`,
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: 8,
    },
    "&:first-child": {
      borderRadius: 8,
    },
  },
}))(ToggleButtonGroup);

export default function FontSelector({ fonts,  isSingle, handleState,familykey,fontfamilyvalue ,handleObject,statename,objectKey,objectName}) {
  const classes = useStyles();

  const [fontItems, setFontItems] = useState([]);

  const handleChangeFont = (event, newValue) => {
    console.log(newValue);
    if(isSingle) {
      handleObject(statename,objectKey,objectName,[newValue]);
      return;
    } else{
      debugger;
      handleState(familykey,newValue)
    }
    // setFontItems(newValue);
  };

  console.log("Something  went wrong", fontItems);

  return (
    <div style={{ padding:12}}>
      <StyledToggleButtonGroup
        size="small"
        exclusive={isSingle}
        aria-label="text alignment"
        value={fontfamilyvalue}
        onChange={handleChangeFont}
      >
        {fonts.map((font) => (
          <ToggleButton
            className={
              Array.isArray(fontfamilyvalue) && fontfamilyvalue?.some((item) => item?.value === font?.value)
                ? classes.selectedFont
                : classes.fontBtn
            }
            value={font}
          >
            {font?.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </div>
  );
}
