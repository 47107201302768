import * as React from "react"

function TypographyIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={81} height={16} {...props}>
      <g fill={props.color}>
        <text
          transform="translate(16 12)"
          fontSize={props.fontSize}
          fontFamily="NunitoSans-SemiBold,Nunito Sans"
          fontWeight={props.fontWeight}
        >
          <tspan x={0} y={0}>
            {"Typography"}
          </tspan>
        </text>
        <path d="M10.167 2a.5.5 0 00-.5.493.17.17 0 01-.167.174H1.167A.17.17 0 011 2.493.5.5 0 000 2.5v2.333a.5.5 0 001 0v-.666a.5.5 0 01.5-.5H4a.666.666 0 01.667.667v8A.666.666 0 014 13h-.833a.5.5 0 000 1H7.5a.5.5 0 000-1h-.833A.666.666 0 016 12.333v-8a.666.666 0 01.667-.667h2.5a.5.5 0 01.5.5v.667a.5.5 0 001 0V2.5a.5.5 0 00-.5-.5z" />
      </g>
    </svg>
  )
}

export default TypographyIcon;
