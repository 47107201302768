import axios from "axios";
import Config from "../../src/config";
import { v4 as uuid } from "uuid";
export const Axios = (params) => {
  return new Promise((resolve, reject) => {
    axios(params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//create Roles
export const addRole = (token = "", name = "") => {
  return new Promise((resolve, reject) => {
    let myHeaders = {};

    let params = {
      name: name,
      composite: false,
      clientRole: false,
      containerId: Config.secretKey,
    };
    myHeaders.Authorization = "Bearer " + token;
    myHeaders["Content-Type"] = "application/json";
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: params,
      redirect: "follow",
      url: `${Config.nifi_api}/auth/admin/realms/${Config.realm}/roles`,

      // url:`${Config.nifi_api}/auth/admin/realms/${Config.realm}/clients/${Config.secretKey}/roles`
    };
    Axios(requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//get RoleId
export const getRoleId = (token, roleName) => {
  return new Promise((resolve, reject) => {
    var myHeaders = {};
    myHeaders.Authorization = "Bearer " + token;

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      url: `${Config.nifi_api}/auth/admin/realms/${Config.realm}/roles/${roleName}`,
    };

    Axios(requestOptions)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

//create users
export const addRolesToKeyClock = (token = "", roleName = "") => {
  return new Promise((resolve, reject) => {
    var myHeaders = { "Content-Type": "text/plain" };

    var raw = {
      name: roleName,
      composite: false,
      clientRole: false,
      containerId: "organization",
      attributes: {},
    };
    var encoded = btoa(JSON.stringify(raw));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: encoded,
      redirect: "follow",
      url: `${Config.nifi_api}/api/v1/createRealmRoles`,
    };

    return Axios(requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//create users
export const addRolesForUser_KeyClock = (Uname = "", Rname = "") => {
  return new Promise((resolve, reject) => {
    var myHeaders = { "Content-Type": "text/plain" };

    var rawData = {
      username: Uname,
      rolesName: Rname,
    };
    var encoded = btoa(JSON.stringify(rawData));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: encoded,
      redirect: "follow",
      url: `${Config.nifi_api}/api/v1/assignUserRoles`,
    };

    return Axios(requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//create users
export const addUserToKeyClock = (
  token = "",
  email = "",
  username = "",
  lastName = ""
) => {
  return new Promise((resolve, reject) => {
    var myHeaders = { "Content-Type": "text/plain" };

    // myHeaders.Authorization = "Bearer " + token;
    // myHeaders['Content-Type'] = "application/json";

    var raw = {
      username: username,
      enabled: true,
      totp: false,
      emailVerified: false,
      firstName: username,
      lastName: username,
      email: email,
      disableableCredentialTypes: [],
      requiredActions: [],
      notBefore: 0,
      access: {
        manageGroupMembership: true,
        view: true,
        mapRoles: true,
        impersonate: true,
        manage: true,
      },
      realmRoles: [],
    };
    var encoded = btoa(JSON.stringify(raw));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: encoded,
      redirect: "follow",
      url: `${Config.nifi_api}/api/v1/createQdmAdminConsoleUser`,

      // url:`${Config.nifi_api}/auth/admin/realms/${Config.realm}/users`
    };

    return Axios(requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//delete roles
export const deleteRoleToKeyClock = (roleName = "") => {
  return new Promise((resolve, reject) => {
    var myHeaders = { "Content-Type": "text/plain" };

    // myHeaders.Authorization = "Bearer " + token;
    // myHeaders['Content-Type'] = "application/json";

    var raw = {
      name: roleName,
    };
    var encoded = btoa(JSON.stringify(raw));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: encoded,
      redirect: "follow",
      url: `${Config.nifi_api}/api/v1/deleteRealmRoles`,

      // url:`${Config.nifi_api}/auth/admin/realms/${Config.realm}/users`
    };

    return Axios(requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//update role
export const updateRoleKeyClock = (newRoleName = "", oldRoleName = "") => {
  return new Promise((resolve, reject) => {
    var myHeaders = { "Content-Type": "text/plain" };

    // myHeaders.Authorization = "Bearer " + token;
    // myHeaders['Content-Type'] = "application/json";

    var raw = {
      newRolesName: newRoleName,
      oldRolesName: oldRoleName,
    };
    var encoded = btoa(JSON.stringify(raw));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: encoded,
      redirect: "follow",
      url: `${Config.nifi_api}/api/v1/updateRealmRoles`,

      // url:`${Config.nifi_api}/auth/admin/realms/${Config.realm}/users`
    };

    return Axios(requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//delete users
export const deleteUserToKeyClock = (email = "") => {
  return new Promise((resolve, reject) => {
    var myHeaders = { "Content-Type": "text/plain" };

    // myHeaders.Authorization = "Bearer " + token;
    // myHeaders['Content-Type'] = "application/json";

    var raw = {
      username: email,
    };
    var encoded = btoa(JSON.stringify(raw));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: encoded,
      redirect: "follow",
      url: `${Config.nifi_api}/api/v1/deleteOrgUser`,

      // url:`${Config.nifi_api}/auth/admin/realms/${Config.realm}/users`
    };

    return Axios(requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


 export const  updatePageandRouteToKeyClock =(newpageName ,oldpageName)=>{
    return new Promise((resolve, reject) =>{
    
       var myHeaders = {"Content-Type": "text/plain"};

       var raw = {
           newpageName: newpageName,
           oldpageName: oldpageName,
       }
       var encoded = btoa(JSON.stringify(raw));

       var requestOptions = {
        method: "POST",
        headers: myHeaders,
        data: encoded,
        redirect: "follow",
        url: `${Config.nifi_api}/api/v1/updateQdmAdminConsoleUser`,
  
        // url:`${Config.nifi_api}/auth/admin/realms/${Config.realm}/users`
      };

      

    })
 }



//UPDATE users
export const updateUserToKeyClock = (userName) => {
  return new Promise((resolve, reject) => {
    var myHeaders = { "Content-Type": "text/plain" };

    var raw = {
      username: userName,
      enabled: true,
      emailVerified: true,
      firstName: userName,
      lastName: userName,
      email: userName,
      disableableCredentialTypes: [],
      requiredActions: [],
      notBefore: 0,
      access: {
        manageGroupMembership: true,
        view: true,
        mapRoles: true,
        impersonate: true,
        manage: true,
      },
      realmRoles: ["mb-user"],
    };
    var encoded = btoa(JSON.stringify(raw));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: encoded,
      redirect: "follow",
      url: `${Config.nifi_api}/api/v1/updateQdmAdminConsoleUser`,
    };

    return Axios(requestOptions)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserId = (token, emailId) => {
  return new Promise((resolve, reject) => {
    var myHeaders = {};
    myHeaders.Authorization = "Bearer " + token;

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      url: `${Config.nifi_api}/auth/admin/realms/${Config.realm}/users?username=${emailId}`,
    };

    Axios(requestOptions)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const updateUser = (token, userId, email) => {
  return new Promise((resolve, reject) => {
    var myHeaders = {};
    myHeaders.Authorization = "Bearer " + token;
    myHeaders["Content-Type"] = "application/json";

    var raw = { email: email, username: email };

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      data: raw,
      redirect: "follow",
      url: `${Config.nifi_api}/auth/admin/realms/${Config.realm}/users/${userId}`,
    };

    Axios(requestOptions)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
  // if (!userId) {
  //     return false;
  // }
};
//insert document to arango
export const upsertDocument = (params) => {
  return new Promise((resolve, reject) => {
    let dataList = {
      db_name: Config.database,
      entity: params.entity,
      is_metadata: false,
      // metadataId: params.metadataId,
      doc: {
        // [params.upsertNameKeyId]: params.upsertNameValue,
        ...params.list,
      },
    };
    if (params.isedit) {
      dataList.filter = {
        [params.keyvalue]: params.id,
      };
    }
    let data = JSON.stringify([dataList]);
    let config = {
      method: "post",
      url: `${Config.entity_api_url}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    Axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//read document from arango database

export const readDocument = (params) => {
  return new Promise((resolve, reject) => {
    let data = {
      db_name: `${Config.database}`,
      entity: `${params.entity}`,
      // filter: `${params.entity}.${params.filterId} == '${params.filterValue}'`,
      return_fields: `{${params.entity}}`,
    };
    

    
    if (params?.filter) {
      data.filter = params.filter;
    }
    let config = {
      method: "post",
      url: `${Config.entity_api_url}/api/read_documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    Axios(config)
      .then((res) => {
        if(res?.data?.result?.length>0){
          res.data.result = res?.data?.result?.map(_=>{
            return{
              ..._[params.entity]
            }
          })
        }
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//get practiesner role

export const getPractioner = (params) => {
  return new Promise((resolve, reject) => {
    let data = {
      db_name: `${Config.database}`,
      entity: `Practitioner,PractitionerRole`,
      filter : `Practitioner.PractitionerRoleID==PractitionerRole.id and Practitioner.PersonID==${params.personId}`,
      return_fields: `{Practitioner:MERGE(PractitionerRole,{code:(FOR codab IN TO_ARRAY(PractitionerRole.code) RETURN MERGE(DOCUMENT(codab),{coding:(FOR cod IN DOCUMENT(codab).coding RETURN DOCUMENT(cod))})  )})}`,
    };
    

    
    // if (params?.isfilter) {
    //   data.filter = `Practitioner.PractitionerRoleID==PractitionerRole.id and Practitioner.PersonID==${params.personId}`;
    // }
    let config = {
      method: "post",
      url: `${Config.entity_api_url}/api/read_documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    Axios(config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const deleteDocument = (params) => {
  return new Promise((resolve, reject) => {
    let data = {
      db_name: `${Config.database}`,
      entity: `${params.entity}`,
      // filter: `${params.entity}.${params.filterId} == '${params.filterValue}'`,
      return_fields: `{${params.entity}}`,
    };
    
    if(typeof(params.id[0]) === 'number'){
      data.filter = `${params.entity}.${[params.keyvalue[0]]}==${params.id[0]}`;

    } else {
      data.filter = `${params.entity}.${[params.keyvalue[0]]}=='${params.id[0]}'`;

    }
    let config = {
      method: "post",
      url: `${Config.entity_api_url}/api/delete_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    Axios(config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


//fetch client against project

export const projectReadDocument = (params) => {
  return new Promise((resolve, reject) => {
    let data = {
      "db_name": `${Config.database}`,
      "entity": "client_project_E",
      "filter":`client_project_E._from=='${params._id}'`,
      "return_fields": "DOCUMENT(client_project_E._to)"
      }; 
    let config = {
      method: "post",
      url: `${Config.entity_api_url}/api/read_documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    Axios(config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//fetch client details
export const clientReadDocument = () => {
  return new Promise((resolve, reject) => {
    let data = {
      "db_name":`${Config.database}`,
      "entity": "clients",
      "filter":"clients.activestatus==true",
      "return_fields": "merge(clients,{project:(for prj in client_project_E filter clients._id==prj._from collect with count into length return {count:length})})"
      }
    
    let config = {
      method: "post",
      url: `${Config.entity_api_url}/api/read_documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    Axios(config)
      .then((res) => {
        // if(res?.data?.result?.length>0){
        //   res.data.result = res?.data?.result?.map(_=>{
        //     return{
        //       ..._[params.entity]
        //     }
        //   })
        // }
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//upload image

export const uploadImage = (fileList) => {
  return new Promise(async (resolve, reject) => {
    if (!fileList && !Array.isArray(fileList)) {
      resolve([]);
      return;
    }
    debugger;
    let calls = await UploadFun(fileList[0])
    Promise.all([calls]).then(data => {
      let dataList = [];
      data.map(val => {
        if (val.fileid) {
          dataList.push({
            fileid: val.fileid,
            filetype: val.filetype,
            objectid: val.objectid,
            filename: val.fileName
          })
        }
        return false
      })

      resolve(dataList);
    })
      .catch(err => {

        resolve([]);
        console.log(err);
      })
  })
  // fetch("http://164.52.210.54:5000/primarycare/11", requestOptions)
}

export const UploadFun = async (file) => {
  return new Promise(async (resolve, reject) => {
    let formData = new FormData();
    formData.append('files', file);

    await fetch(`${Config.file_api_url}/11`, {
      method: "POST",
      body: formData,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then(res => {

        resolve({
          fileid: res.fileid,
          fileName: file.name,
          filetype: file.type,
          objectid: res.objectid
        })
      }).catch(err => {

        resolve({})
      })
  })
}

//bulk upsertdocument

export const bulkUpsertDocument = (params) => {
  return new Promise((resolve, reject) => {
  
    let data = JSON.stringify(params);
    let config = {
      method: "post",
      url: `${Config.entity_api_url}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    Axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const projectIdReadDocument = (params) => {
  return new Promise((resolve, reject) => {
    let data = {
      "db_name": `${Config.database}`,
      "entity": "projects",
      "filter": `projects._id=='${params.id}' AND projects.active==true`,
      "return_fields": "{projects:merge(projects,{projectpaltforms:document(projects.projectpaltforms),projectfonts:document(projects.projectfonts),projectlang:document(projects.projectlang),projectusers:document(projects.projectusers)}),projectpalette:(FOR projectpalette IN projectpalette filter projects._id == projectpalette.projectId return merge(projectpalette,{projectId:document(projectpalette.projectId)})),projecttypography:(FOR projecttypography IN projecttypography filter projects._id == projecttypography.projectId return merge(projecttypography, {projectId:document(projecttypography.projectId),typographyid:document(projecttypography.typographyid),typofonts:document(projecttypography.typofonts),fontweight:document(projecttypography.fontweight)}))}"
    }
    
    let config = {
      method: "post",
      url: `${Config.entity_api_url}/api/read_documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    Axios(config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//project delete document

export const deleteProjectEdgeDocument = (params) => {
  return new Promise((resolve, reject) => {

    let config = {
      method: "post",
      url: `${Config.entity_api_url}/api/delete_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    Axios(config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//save schema

export const saveSchema = (clone_pay) =>{
  return new Promise(async(resolve,reject)=>{
    await axios
    .post(`${Config.Entity_Adoptor_URL}/api/save_schema`, clone_pay)
    .then(async res => {
      // let value = sessionStorage.metaId;
      if(res.data.Code === 201){
        let value = res.data.clientdb.Result[0].properties.doc.metadataId;
        await axios
          .post(`${Config.Entity_Adoptor_URL}/api/get_schema`, {
            filter: {
              columname: 'metadataId',
              operator: 'equals',
              value: value
            },
            metadata_dbname:Config.database

          })
          .then(async(response) => {
            if(response.data.Code === 201){
              await axios
              .post(`${Config.Entity_Adoptor_URL}/api/publish_schema`, {...response.data.Result,metadata_dbname:Config.database })
              .then(async res => {
                  resolve(res);
              })
              .catch(err => {
                  resolve(err);
              })
            } else {
              resolve({
                success:false,
                msg:"get schema failed"
              });
            }
           
          })
          .catch(err => {
            resolve(err);
          
           })
      } else {
        resolve({
          success:false,
          msg:"save schema failed"
        });
    
      }
     
    })
    .catch(err => {
      resolve(err);

    })
  })
}