/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2020-11-27
 * @modify date 2020-12-01
 * @desc Providing the AlertContext from (/src/context/) which is used by /src/App.js.
 */

 import React from "react";
 import  Alert  from "./components/alert";
 import { alertProps } from "./utils";
 import { AlertContext } from "./contexts";
 
 class AppAlert extends React.Component {
   constructor(props) {
     super(props);
     this.state = {
       open: false,
       severity: alertProps.severity.success,
       msg: "",
       vertical: alertProps.vertical.top,
       horizontal: alertProps.horizontal.center,
       transitionDuration: 5000,
     };
   }
 
   close = () => {
     this.setState({
       open: false,
       severity: alertProps.severity.success,
       msg: "",
       vertical: alertProps.vertical.top,
       horizontal: alertProps.horizontal.center,
       transitionDuration: 5000,
     });
   };
 
   set = (props) => {
     this.setState({ ...props });
   };
 
   render() {
     return (
       <AlertContext.Provider
         value={{
           ...this.state,
           onclose: this.close,
           setSnack: this.set,
         }}
       >
         {this.state.open ? <Alert {...this.state} onclose={this.close} /> : ""}
         {this.props.children}
       </AlertContext.Provider>
     );
   }
 }
 
 export default AppAlert;
 