
/*
createdBy:Manikandan
createdAt:25/01/2022
email:mani@crayond.co
*/

import {  Grid, Typography } from '@material-ui/core';
import React from 'react';
import { styles } from '../homeRouterPageStyle';
import { makeStyles } from "@material-ui/core/styles";
import { SubHeader } from "../subNav";
import config from "../../config";


const useStyle = makeStyles(styles);


function ToolCompoent(props){
    const  classes = useStyle();
    const { routerNavigation,backToProject,tools } = props;
    return(

        <React.Fragment>
           <Grid container>
                <SubHeader
                  title="Tools"
                  hideSearch
                  backButtonAction={() => backToProject()}
                />

                <Grid container className={classes.containerStyle}>
                  {tools?.map((v) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{
                          padding: 16,
                          pointerEvents: !v?.active && "none",
                        }}
                        className={classes.hoverEffect}
                      >
                        <div
                          className={classes.paper}
                          style={{
                            minHeight: 150,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: 0,
                          }}
                          onClick={() => routerNavigation(v._id, v.toolid)}
                        >
                          <img
                            src={
                              `${config.file_api_url}/${v?.images[0]?.fileid}` ??
                              ""
                            }
                            // alt={`${config.file_api_url}/${v?.images[0]?.fileid}` ?? ''}
                            style={{
                              marginBottom: 10,
                              width: 50,
                              height: 50,
                            }}
                          />
                          <Typography
                            variant="h6"
                            style={{
                              color: !v?.active && "grey",
                              textAlign: "center",
                            }}
                          >
                            {v?.toolname}
                          </Typography>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
        </React.Fragment>
    )
}

export default ToolCompoent