import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomAutocomplete from "../autoComplete";
import Andriod from "../icons/andriodIcon";
import Ios from "../icons/iosIcon";
import WebIcon from "../icons/webIcon";
import Uploader from "../uploader";
import CustomToggleBtn from "../platform-button";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    float: "right",
  },
  instructions: {
    paddingBottom: 10,
  },
  input: {
    display: "none",
  },
  projectTextFiled: {
    background: "#D6D6D614 0% 0% no-repeat padding-box",
    border: "1px solid #110F4714",
    borderRadius: "4px",
    opacity: 1,
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-input": {
        padding: 5,
      },
    },
  },
  UploadBtn: {
    marginLeft: 13,
  },
  projectInfo: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 90,
  },
  fileUploadStatus: {
    border: "2px dashed #e9e9ee ",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#fcfcfc",
    padding: 10,
    borderRadius: "5px",
  },
  fileName: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  fileSize: {
    fontSize: "10px",
    marginTop: 5,
    color: "#CCC",
  },
  fileRemove: {
    fontSize: "10px",
    border: "none",
    color: "#e34d65",
  },
  connectGitrepo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  connectGitLabel: {
    fontSize: "20px",
  },
  connectGitBtn: {
    color: `${theme.palette.primary.main}`,
    padding: "8px 20px",
    fontSize: 15,
    textTransform: "capitalize",
  },
}));



export default function ProjectInfo(props) {
  const classes = useStyles();
  const addIcons = ()=>{
    let modify=    props.projectPlatform.map(_=>{
        if(_.label.toLowerCase()=='andriod'){
          _.icon = (color) => <Andriod color={color} />
        } 
        if(_.label.toLowerCase()=='ios'){
          _.icon = (color) => <Ios color={color} />
        }
        if(_.label.toLowerCase()=='Web Responsive'){
          _.icon = (color) => <Ios color={color} />
        }
        return _;
     });
     return modify;
   
  }
  return (
    <>
      <Grid item xs={12}>
        {/* Project Name */}
        <Typography className={classes.instructions}>Database Name<span style={{color:"red"}}>*</span></Typography>
        <TextField
          disabled={Boolean(props.is_update)}
          id="outlined-basic"
          className={classes.projectTextFiled}
          fullWidth
          variant="outlined"
          value={props.databaseName}
          onChange={(e)=>props.handleState('databaseName',e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        {/* Project Name */}
        <Typography className={classes.instructions}>Project Name<span style={{color:"red"}}>*</span></Typography>
        <TextField
          id="outlined-basic"
          className={classes.projectTextFiled}
          fullWidth
          variant="outlined"
          value={props.projectName}
          onChange={(e)=>props.handleState('projectName',e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        {/* Project Platform */}
        <Typography className={classes.instructions}>
          Project Platform<span style={{color:"red"}}>*</span>
        </Typography>
        <CustomToggleBtn
          toggleBtns={props.masterProjectPlatform}
          handleState={props.handleState}
          projectPlatform={props.projectPlatform}
        />
      </Grid>
      <Grid item xs={12}>
        {/* Upload Logo */}
        <Typography className={classes.instructions}>Upload Logo</Typography>
        <Uploader handleUploadIcon={props.handleUploadIcon}/>
        {
          props.uploadImages?.length>0 &&  <img
          src={`${config.file_api_url}/${props?.uploadImages[0]?.fileid}` ?? ''}
          // alt={`${config.file_api_url}/${v?.images[0]?.fileid}` ?? ''}
          style={{
            width: 50,
            height: 50,
            display: "flex",
            marginTop: "10px"
          }}
        />
        }
      </Grid>
      <Grid item xs={12}>
        {/* Choose Language */}
        <CustomAutocomplete
          placeholder={"Choose Language*"}
          label={""}
          options={props.masterLanguage}
          handleState = {props.handleState}
          value = {props.language}
          fieldkey='language'
        />
      </Grid>
      <Grid item xs={12}>
        {/* Connect Git Repo */}
        <div className={classes.connectGitrepo}>
          <Typography className={classes.connectGitLabel}>
            Connect Git Repo
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            className={classes.connectGitBtn}
          >
            Connect
          </Button>
        </div>
      </Grid>
    </>
  );
}
