import { Box, Divider, Grid, withStyles } from "@material-ui/core";
import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import "../../App.css";
import FontSelector from "../fontSelector";
import TypographyTab from "../themeTabs/typographyTab";
import PaletteIcon from "../icons/paletteIcon";
import FontsIcon from "../icons/fontsIcon";
import TypographyIcon from "../icons/typographyIcon";
import {
  typographyMetaData,
  fontsArray,
  selectedFonts,
  palette,
} from "./utils.js";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  themeAccord: {
    "&.MuiAccordionSummary-content": {
      justifyContent: "space-between",
    },
  },

  // Accordion
  accordionRoot: {
    boxShadow: "unset",
    borderBottom: "1px solid #110F4714",
  },

  // Accordion Summary
  accordionSummaryRoot: {
    padding: "0px",
  },
  accordionSummaryContent: {
    "&.Mui-expanded": {
      margin: "12px 0px",
    },
  },

  // Accordion Detail
  accordionDetailRoot: {
    padding: "0px !important",
  },
  fontsAvailable: {
    marginTop: 5,
    border: "1px solid #ccc",
    borderRadius: "2px",

    "& .MuiToggleButtonGroup-root": {
      display: "block",
      "& .MuiToggleButton-root": {},
    },
  },
  themeModeOff: {
    color: theme.palette.text.secondary,
  },
  themeModeOn: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  modeOfTheme: {
    "& .MuiTypography-root": {
      padding: "0px 10px",
    },

    "& .MuiSwitch-root": {
      overflow: "visible",
    },
  },

  // Tab
  tabRoot: {
    minWidth: 80,
    maxWidth: 120,
    textTransform: "none",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Theme(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12}>
        <div
          style={{
            width: "100%",
            margin: "0px 30px",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Tabs
            value={props.value}
            indicatorColor="primary"
            onChange={props.handleChange}
          >
            <Tab
              label={
                <PaletteIcon
                  color={props.value === 0 ? theme.palette.primary.main : ""}
                />
              }
              {...a11yProps(0)}
              classes={{
                root: classes.tabRoot,
              }}
            />
            <Tab
              label={
                <FontsIcon
                  fontSize={12}
                  fontWeight={"light"}
                  color={props.value === 1 ? theme.palette.primary.main : ""}
                />
              }
              {...a11yProps(1)}
              classes={{
                root: classes.tabRoot,
              }}
            />
            <Tab
              label={
                <TypographyIcon
                  fontSize={12}
                  fontWeight={"light"}
                  color={props.value === 2 ? theme.palette.primary.main : ""}
                />
              }
              {...a11yProps(2)}
              classes={{
                root: classes.tabRoot,
              }}
            />
          </Tabs>
        </div>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <TabPanel value={props.value} index={0} style={{ width: "100%" }}>
          <Grid item xs={12}>
            {/* Type */}
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                gap: "12px",
                flexGrow: 1,
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <Typography>Type</Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 5px",
                }}
                className={classes.modeOfTheme}
              >
                <Typography
                  className={
                    props.themeSwitcher
                      ? classes.themeModeOff
                      : classes.themeModeOn
                  }
                >
                  Light
                </Typography>
                <AntSwitch
                  checked={props.themeType==='dark' ? true: false}
                  onChange={(e)=>props.handleState('themeType',e.target.checked ? 'dark' : 'light')}
                  name="checkedC"
                />

                <Typography
                  className={
                    props.themeSwitcher
                      ? classes.themeModeOn
                      : classes.themeModeOff
                  }
                >
                  Dark
                </Typography>
              </div>
            </div>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            {/* Palette Generator*/}
            <div className={classes.root}>
              {props?.palette.map((item) => (
                <Accordion classes={{ root: classes.accordionRoot }}>
                  <AccordionSummary
                    className={classes.themeAccord}
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                      root: classes.accordionSummaryRoot,
                      content: classes.accordionSummaryContent,
                    }}
                  >
                    <Box
                      flexGrow={1}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className={classes.heading}>
                        {item.label}
                      </Typography>
                      <div style={{ display: "flex", gap: "4px" }}>
                        {item.props.map((prop) => (
                          <div
                            style={{
                              backgroundColor: `${prop.color}`,
                              width: "10px",
                              height: "13px",
                              border: "0.4px solid #000000",
                            }}
                          ></div>
                        ))}
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "24px",
                    }}
                    classes={{
                      root: classes.accordionDetailRoot,
                    }}
                  >
                    {item.props.map((prop) => (
                      <div style={{ marginBottom: 12 }}>
                        <Typography
                          color="textSecondary"
                          style={{ marginBottom: 8 }}
                        >
                          {prop.label}
                        </Typography>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            className="color-picker-styles"
                            type="color"
                            value={prop.color}
                            style={{
                              width: "30px",
                              height: "20px",
                              // borderRadius: "3px",
                              // border: "0.5px solid black",
                              marginRight: 24,
                              padding: 0,
                            }}
                            onChange={(e)=>props.handleObject('palette',item?.label,prop?.label,e.target.value)}

                          ></input>

                          <Typography style={{ flex: 1 }}>
                            {prop.color}
                          </Typography>
                          <Typography color={"textSecondary"}>Auto</Typography>
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Grid>
        </TabPanel>
        <TabPanel value={props.value} index={1}>
          <Typography>Available Fonts</Typography>

          <div className={classes.fontsAvailable}>
            <FontSelector fonts={props.masterFontFamily} handleState ={props.handleState}
             familykey ='availableFonts'
             fontfamilyvalue={props.availableFonts}
             />
          </div>
        </TabPanel>
        <TabPanel value={props.value} index={2}>
          {/* TypoGraphy */}
          <TypographyTab fonts={props.availableFonts} 
             metaData={props.masterTypograpy} 
             masterFontWeight ={props.masterFontWeight} 
             handleObject={props.handleObject}
           />
        </TabPanel>
      </Grid>
    </>
  );
}
