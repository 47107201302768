import React from 'react';

//HOC's
import AppAuth from './App.auth';
import AppTheme from './App.theme';
import AppEditables from './app.edittables';
import AppGQLClient from './App.gqlclient';
import AppAlert from './App.alert';
import AppErrorBoundary from './App.error_boundry';
import RouterApp from './router';
import AppDrawer from "./App.drawer";
import AppLanguages from './App.languages'
import AppBackdrop from './App.backdrop';
import { Provider } from "react-redux";
import { store as ReduxStore } from "./redux";

function App() {
  return <Provider store={ReduxStore}>
     <AppAuth>
    <AppErrorBoundary>
      <AppTheme>
        <AppGQLClient>
          <AppAlert>
            <AppEditables>
            <AppBackdrop>
            <AppDrawer>
              <AppLanguages>
              <RouterApp />
              </AppLanguages>
              </AppDrawer>
              </AppBackdrop>
            </AppEditables>
          </AppAlert>
        </AppGQLClient>
      </AppTheme>
    </AppErrorBoundary>
  </AppAuth>
</Provider>
  
}

export default App;