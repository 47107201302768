import React from "react";
import { BalancePayloadContext,DrawerContext,LanguageContext,AlertContext,PermissionContext,BackdropContext} from "../contexts";


const withAllContexts = (Component) => (props) => {
  const balancePayload = React.useContext(BalancePayloadContext);
  const drawer = React.useContext(DrawerContext);
  const lang = React.useContext(LanguageContext)
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);

  return (
    <Component {...props} balancePayload={balancePayload} drawer={drawer} lang={lang} alert={alert} backdrop={backdrop} >
      {props.children}
    </Component>
  );
};

export default withAllContexts;