

/*
createdBy:Manikandan
createdAt:25/01/2022
email:mani@crayond.co
*/
import { projectPaletteInfo,validationFieldForProject } from "../../utils/constants";

export const stateProperties = {
    addproject: false,
    addprojectvalue: "",
    theme: "",
    data: [],
    isopen: false,
    snackmsg: "",
    snacktype: "",
    tab: 0,
    tabIndex: 0,
    qpasData: [],
    addclient: false,
    clientList: [],
    listClient: [],
    status: "client",
    projectTools: [],
    // projectToolLists: [],
    headerName: ['QDM'],
    projectId: "",
    metaDataId: "",
    databaseName: "",
    metaDataName: "",
    userdetails: {},

    clientDrawer: false,
    userDrawer: false,
    username: "",
    email: "",
    designation: "",
    userError: {
      username: false,
      email: false,
    },
    addclientvalue: "",
    clientError: {
      addclientvalue: false,
    },
    clientErrorMsg: {
      addclientvalue: "Please enter the field",
    },
    deleteDrawer: false,
  
    deleteClient: false,
    deleteClientId: "",
    projectName: "",
    enableEdit: false,
    editProjectId: "",
    anchorEl: null,
    activeStep: 0,
    tabValue: 0,
    themeSwitcher: false,
    selectedFont: false,
    fontFamily: [],
    client_id: "",
    tools: [],
    project_vs_tools: [],
    masterProjectPlatform: [],
    masterLanguage: [],
    projectName: "",
    deleteProject: false,
    language: [],
    themeType: "light",
    client_key: "",
    palette: projectPaletteInfo,
    availableFonts: [],
    masterTypograpy: [],
    cloneMasterTypograpy: [],
    masterFontWeight: [],
    masterFontFamily: [],
    users: [],
    masterUsers: [],
    keyValues: [
      {
        key: "",
        value: "",
      },
    ],
    projectPlatform: [],
    databaseName: "",
    uploadImages: [],
    _idOfproject: "",
    projectPaletteId: "",
    validatation: validationFieldForProject,
    is_update: false,
  }