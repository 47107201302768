import React,{ useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";



const useStyles = makeStyles((theme) => ({
    UploadBtn: {
     
    },
    fileUploadStatus: {
      border: "2px dashed #e9e9ee ",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#fcfcfc",
      padding: 10,
      borderRadius: "5px",
    },
    fileName: {
      fontSize: "10px",
      fontWeight: "bold",
    },
    fileSize: {
      fontSize: "10px",
      marginTop: 5,
      color: "#CCC",
    },
    fileRemove: {
      fontSize: "10px",
      border: "none",
      color: theme.palette.error.main,
    },
  
  }));


export default function Uploader(props) {
    const classes = useStyles();
    const [logoFiles, setLogoFiles] = useState(null);

    const handleUploadLogo = (e) => {
      const files = e.target.files;
      const filesArray = Object.entries(files);
      console.log(filesArray[0][1].name);
      const filename = filesArray[0][1].name;
      const fileSize = (filesArray[0][1].size / 1000).toFixed(2);
  
      props.handleUploadIcon(files);
    };
    
  return (
    <>
      <input
        accept="image/*"
        id="logoUpload"
        type="file"
        hidden
        onChange={(e) => handleUploadLogo(e)}
      />
      {logoFiles ? (
        <div className={classes.fileUploadStatus}>
          <Typography classname={classes.fileName}>
            {logoFiles?.logoName}
          </Typography>
          <Typography
            className={classes.fileSize}
          >{`${logoFiles?.logoSize} kb`}</Typography>
          <Button
            variant="outlined"
            color="red"
            startIcon={<DeleteOutlineIcon />}
            onClick={() => setLogoFiles(null)}
            className={classes.fileRemove}
          >
            remove
          </Button>
        </div>
      ) : (
        <label htmlFor="logoUpload">
          <Button
            variant="outlined"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
            className={classes.UploadBtn}
          >
            Upload
          </Button>
        </label>
      )}
    </>
  );
}
